import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"

const addressKeysMapping = {
  name: "Name",
  street: "Straße",
  postcodeCity: "PLZ Ort",
  phoneNumber: "Telefon",
  email: "Email"
}

const VerifyAddress = ({values}) => {
  const { address } = values
  const filteredAddressKeys = Object.keys(address)
    .filter(value => address[value])
    
  const RenderAddress = () => {
    const filteredAddress = filteredAddressKeys.map((item, index) => {
      return (
        <tr key={index}> 
          <td width="15%" className="text-center">
            {addressKeysMapping[item]}
          </td>
          <td>
            {address[item]}
          </td>
        </tr>
      )
    })
    return (
      <Fragment>
        {filteredAddress}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Praxis Adresse"
      >
        <RenderAddress />
      </Table>
    </Fragment>
  )
}

export default VerifyAddress