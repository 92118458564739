import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"
import { TextInput } from "../../UI/Input/Input"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"

export const Address = ({values, errors}) => {
  const hasError = ["name", "street", "postcodeCity", "phoneNumber", "email"].some(value => {
    return errors && errors.address && errors.address[value]
  })
  
  return (
    <Fragment>
      <Table 
        hasError={hasError}
        headline="Praxis Adresse"
      >
        <tr>
          <td className="text-center" width="15%">
            Name
          </td>
          <td>
            <TextInput 
              name={`address[name]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
            />
          </td>
        </tr>
        <tr>
          <td className="text-center">
            Straße
          </td>
          <td>
            <TextInput 
              name={`address[street]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
            />
          </td>
        </tr>
        <tr>
          <td className="text-center">
            PLZ, Ort
          </td>
          <td>
            <TextInput 
              name={`address[postcodeCity]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
            />
          </td>
        </tr>
        <tr>
          <td className="text-center">
            Telefon
          </td>
          <td>
            <TextInput 
              name={`address[phoneNumber]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
            />
          </td>
        </tr>
        <tr>
          <td className="text-center">
            Email
          </td>
          <td>
            <TextInput 
              name={`address[email]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
            />
          </td>
        </tr>
      </Table>
      <TableFooter />
    </Fragment>
  )
}

const TableFooter = () => (
  <Fragment>
    <HorizontalRule />
  </Fragment>
)