import React, { Fragment } from "react"
import { Table, TableIcon } from "../../UI/Table/Table"

const VerifyInsurancePremiumProtection = ({values}) => {
  const { insurancePremiumProtection } = values

  const RenderInsurance = () => {
    if (insurancePremiumProtection) {
      return (
        <td>
          Ja, ich möchte den MSC Premiumschutz abschließen.
        </td>
      )
    }

    return (
      <td>
        Nein, ich möchte den MSC Premiumschutz nicht abschließen.
      </td>
    )
  }

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="MSC Premiumschutz"
      >
        <tr>
          <td width="15%">
            <TableIcon type={insurancePremiumProtection ? "check" : "cross"} />
          </td>
          <RenderInsurance />
        </tr>
      </Table>
    </Fragment>
  )
}

export default VerifyInsurancePremiumProtection