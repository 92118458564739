import React from 'react'
import HorizontalRule from "./../UI/HorizontalRule/HorizontalRule"
import { CruiseDate, CruiseShip, CruiseRoute } from "./../Content/Content"
import { scrollToTop } from "./../../utils/utils"


const Imprint = () => {
  scrollToTop()
  
  return (
    <div className="content-wrapper">
      <p className="intro-text">
        Wir laden Sie vom <CruiseDate className="text-no-break" /> auf der Route <CruiseRoute /> auf die <CruiseShip className="link-simple"/> ein.
      </p>
      <HorizontalRule type="wave" />
      <div className="padding-wide text-medium">
        <h2>Impressum</h2>
        <p className="text-bold">
          Gesundheitszentrum Fortbildungsinstitut Bernau GmbH
        </p>
        <p>
          Jahnstraße 52<br />
          D-16321 Bernau
        </p>
        <p>
          Tel. +49 3338 752716 / Fax. +49 3338 752729 <br />
          E-Mail: <a 
            href="mailto:info@kongress-schiffsreise.de"
            className="link-underline"
          >
            info@kongress-schiffsreise.de
          </a>
        </p>
        <p className="text-bold">
          Geschäftsführerin
        </p>
        <p>
          Dr. Karola Weßlau
        </p>
        <p className="text-bold">
          Handelsregister
        </p>
        <p>
          HRB 10312<br />
          AG Frankfurt (Oder)
        </p>
      </div>
      
      <HorizontalRule />

      <div className="padding-wide text-medium">
        <h2>Allgemeine Geschäftsbedingungen</h2>
        <p>
          <a 
            href="//www.kongress-schiffsreise.de/media/agb/AGB-GFB.pdf"
            className="link-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Allgemeine Geschäftsbedingungen der GFB
          </a>
          <br /> 
          <a 
            href="//www.kongress-schiffsreise.de/media/agb/AGB-MSC.pdf"
            className="link-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Allgemeine Geschäftsbedingungen von MSC Kreuzfahrten
          </a>
        </p>
      </div>

      <HorizontalRule />

      <div className="padding-wide text-medium">
        <h2>Disclaimer</h2>
        <p>
          Mit dem Urteil vom 12. Mai 1998 hat das Landgericht Hamburg (312 O 85/98-Haftung für Links) entschieden, dass man durch die Anbringung eines Links die Inhalte der gelinkten Seite ggf. mit zu verantworten hat. Dies kann, so das Landgericht, nur dadurch verhindert werden, dass man sich ausdrücklich von diesen Inhalten distanziert. Wir haben auf unserer Seite Links zu anderen Seiten im Internet gelegt. Für alle diese Links gilt: Wir erklären ausdrücklich, dass wir keinen Einfluss auf die Gestaltung und die Inhalte der verlinkten Seite haben. Deshalb distanzieren wir uns hiermit ausdrücklich von allen Inhalten aller verlinkten Seiten und machen uns ihre Inhalte nicht zu Eigen. Diese Erklärung gilt für alle auf unserer Seite angebrachten Links zu fremden Seiten.
        </p>
      </div>

      <HorizontalRule type="wave"/>

    </div>
  )
}

export default Imprint