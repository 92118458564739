import React from "react";

export const TripleWave = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="60" height="12" viewBox="0 0 60 12">
    <g fillRule="evenodd">
      <path d="M20 6v6c10 0 10-6 20-6V0C30 0 30 6 20 6zM40 12c10 0 10-6 20-6V0C50 0 50 6 40 6v6zM0 6v6c10 0 10-6 20-6V0C10 0 10 6 0 6z"/>
    </g>
  </svg>
)

export const Asterisk = ({className}) => (
  <svg className={className} width="16" height="18" viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.03 9c2.485.906 4.546 1.39 6.182 2.416.485.302.788.785.788 1.329 0 .906-.727 1.57-1.515 1.57-.243 0-.485-.06-.727-.18-1.697-.967-3.394-2.417-5.152-4.169.424 2.537.97 4.651.97 6.584C9.576 17.336 8.909 18 8 18a1.472 1.472 0 0 1-1.455-1.45c0-1.933.485-4.047.849-6.584-1.758 1.752-3.455 3.202-5.152 4.168-.242.121-.484.181-.727.181-.788 0-1.515-.664-1.515-1.57 0-.544.303-1.027.788-1.329C2.424 10.39 4.485 9.906 6.97 9 4.485 8.094 2.424 7.61.788 6.584.303 6.282 0 5.799 0 5.255c0-.906.727-1.57 1.515-1.57.243 0 .485.06.727.18 1.697.967 3.394 2.417 5.152 4.169-.364-2.537-.849-4.651-.849-6.584C6.545.664 7.212 0 8 0c.91 0 1.576.664 1.576 1.45 0 1.933-.546 4.047-.97 6.584 1.758-1.752 3.455-3.202 5.152-4.168.242-.121.484-.181.727-.181.788 0 1.515.664 1.515 1.57 0 .544-.303 1.027-.788 1.329C13.576 7.61 11.515 8.094 9.03 9z" fillRule="evenodd"/>
  </svg>
)

export const Star = ({className}) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 12.36L12.947 16l-1.894-5.877L16 6.565H9.933L8 .5 6.067 6.565H0l4.947 3.558L3.053 16z" fillRule="evenodd"/>
  </svg>
)

export const Check = ({className}) => (
  <svg className={className} width="18" height="14" viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.72 11.045L1.453 6.692 0 8.164 5.72 14 18 1.472 16.557 0z" fillRule="evenodd"/>
  </svg>
)

export const Cross = ({className}) => (
  <svg className={className} width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z" fillRule="evenodd"/>
  </svg>
)