import React, { Fragment } from "react"
import { TextInput } from "../../UI/Input/Input"
import { Table } from "../../UI/Table/Table"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"

const fellowTravellersTableHead = [
  {
    name: "",
    width: "15%"
  },
  {
    name: "Name",
    width: "85%"
  }
]

export const FellowTravellers = ({values}) => (
  <Fragment>
    <Table 
      hasError={null}
      headline="Wenn es möglich ist, möchte(n) ich/wir mit folgenden Mitreisenden an einem Tisch sitzen"
      tableHead={fellowTravellersTableHead}
    >
      <RenderFellowTravellers count={4} />
    </Table>
    <TableFooter />
  </Fragment>
)

const RenderFellowTravellers = ({count}) => {
  const fellowTravellers = [...Array(count).keys()].map((fellowTraveller, index) => {
    return (
      <tr key={index}>
        <td className="text-center">
          {index + 1}
        </td>
        <td>
          <TextInput 
            name={`fellowTravellers[${index}]`}
            className="one-third-width"
            placeholder="Name"
          />
        </td>
      </tr>
    )
  })
  return fellowTravellers
}

const TableFooter = () => (
  <Fragment>
    <HorizontalRule />
  </Fragment>
)