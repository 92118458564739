import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"
import { List, ListItem } from "../../UI/List/List"
import { congressAttendeeRoles, congressPrice } from "./../../../model/model"
import { perPersonLabel } from "./../../../constants"
import { formatPrice } from "./../../../utils/utils"
import { Star } from "./../../UI/Icons/SVG"
import { EarlyBirdDiscount } from "./../../Content/Content"

export const Price = () => (
  <Fragment>
    <Table 
      hasError={null}
      headline="Kongress-Gebühren"
    >
      <PriceList />
    </Table>
    <TableFooter />
  </Fragment>
)

const PriceList = () => {
  const list = Object.keys(congressAttendeeRoles).map((role, index) => {
    return (
      <tr key={index}>
        <td 
          width="50%" 
          className="left-indent-wide"
        >
          <Star className="text-icon" />
          {congressAttendeeRoles[role].textPlural}
        </td>
        <td className="text-center">
          {formatPrice(congressPrice[role], perPersonLabel)}
        </td>
      </tr>
    )
  })
  return list
}

const TableFooter = () => (
  <Fragment>
    <List className="padding-wide text-intro text-center">
      <ListItem hasDecoration={false}>
        <EarlyBirdDiscount className="text-intro text-center" />
      </ListItem>
    </List>
    <HorizontalRule />
  </Fragment>
)