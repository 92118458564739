import { string, object } from "yup"
import { returnOnEnvironment } from "./../utils/utils"

//
// Define Validation Schema
//

const validateName = string()
  .trim()
  .min(2, "Bitte füllen Sie das Feld aus.")
  .required("Bitte füllen Sie das Feld aus.")

const validateEmail = string()
  .trim()
  .required("Bitte geben Sie eine gültige Emailadresse ein.")
  .email("Bitte geben Sie eine gültige Emailadresse ein.")

export const conferenceRegistrationSchema = object()
  .shape({
    attendee: object({
      surname: validateName,
      lastname: validateName,
      role: string()
        .required("Bitte wählen Sie einen Eintrag aus.")
        .min(1, "Bitte wählen Sie einen Eintrag aus.")
    }),

    address: object({
      name: validateName,
      street: validateName,
      postcodeCity: validateName,
      email: validateEmail,
      phoneNumber: validateName,
    }),
  })

//
// Here I define my forms initial values
//

const attendeeInitialValues = {
  lastname: returnOnEnvironment("Doe", ""),
  surname:  returnOnEnvironment("John", ""),
  role:     returnOnEnvironment("dentist", "")
}

const optionalAttendeesInitialValues = [
  {
    lastname: returnOnEnvironment("Lou", ""),
    surname:  returnOnEnvironment("Mary", ""),
    role:     returnOnEnvironment("assistantDentist", "")
  },
  {
    lastname: returnOnEnvironment("Dalon", ""),
    surname:  returnOnEnvironment("Francis", ""),
    role:     returnOnEnvironment("technician", "")
  },
  {
    lastname: returnOnEnvironment("Kane", ""),
    surname:  returnOnEnvironment("Harry", ""),
    role:     returnOnEnvironment("assistant", "")
  }
]

const addressInitialValues = {
  name:         returnOnEnvironment("John Doe", ""), 
  street:       returnOnEnvironment("Somewhere Road 123", ""),
  postcodeCity: returnOnEnvironment("123456 New Berlin", ""),
  phoneNumber:  returnOnEnvironment("12345-phone", ""),
  email:        returnOnEnvironment("andiw@zedat.fu-berlin.de", "")
}

const surveyInitialValues = {
  text: returnOnEnvironment("foo", ""),
  agency:   returnOnEnvironment(true, false),
  card:     returnOnEnvironment(true, false),
  partner:  returnOnEnvironment(true, false),
  campaign: returnOnEnvironment(true, false)
}

export const conferenceRegistrationInitialValues = {
  attendee:           {...attendeeInitialValues},
  optionalAttendees:  [...optionalAttendeesInitialValues],
  address:            {...addressInitialValues},
  survey:             {...surveyInitialValues},
}