import React from 'react'
import { NavLink } from "react-router-dom"
import { ArrowForward } from "./../UI/Icons/Icons"
import './MainNav.scss'

const MainNavigation = () => {
  return (
    <ul className="main-nav-wrapper">
      <MainNavigationItem 
        label="Ihre Reiseroute" 
        link="/destinations" 
      />
      <MainNavigationItem 
        label="Ihr Fortbildungsprogramm" 
        link="/sessions" 
      />
      <MainNavigationItem 
        label="Reiseanmeldung" 
        link="/cruise" 
      />
      <MainNavigationItem 
        label="Kongressanmeldung" 
        link="/conference" 
      />
      <MainNavigationItem 
        label="Kontakt" 
        link="/contact" 
      />
    </ul>
  )
}

const MainNavigationItem = ({ label, link='#' }) => {
  return (
    <li className="main-nav-item">
      <NavLink 
        to={link} 
        className="main-nav-item-link"
        activeClassName="main-nav-item-link--selected"
      >
        <ArrowForward />
        <span className="main-nav-item-label">
          {label}
        </span>
      </NavLink>
    </li>
  )
}

export default MainNavigation