import React, { Fragment } from "react"
import { CheckboxInput } from "../../UI/Input/Input"
import { MiniButton, ButtonRow } from "../../UI/Button/Button"
import { Table } from "../../UI/Table/Table"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"

export const Insurance = ({values}) => (
  <Fragment>
    <Table 
      hasError={null}
      headline="Reiserücktrittskostenversicherung"
    >
      <tr>
        <td width="15%">
          <CheckboxInput 
            name="insurance"
            className="justified"
            values={values}
          />
        </td>
        <td>
          <label htmlFor="insurance">
            Ja, ich möchte eine Reiserücktrittskostenversicherung abschließen. (ab 150,00 EUR p.P.)
          </label>
        </td>
      </tr>
    </Table>
    <TableFooter />
  </Fragment>
)

const TableFooter = () => (
  <Fragment>
    <p className="padding-wide text-small text-center">
      Reise-Rücktrittsversicherung inkl. Urlaubsgarantie
    </p>
    <ButtonRow>
      <MiniButton 
        file={true} 
        link="//www.kongress-schiffsreise.de/media/versicherung/reiseversicherung.pdf"
      >
        Mehr Informationen
      </MiniButton>
    </ButtonRow>
    <HorizontalRule />
  </Fragment>
)