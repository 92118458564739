import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"
import { congressAttendeeRoles } from "./../../../model/model"

const TableHead = [
  {
    name: "",
    width: "15%"
  },
  {
    name: "Name",
    width: "35%"
  },
  {
    name: "Rolle"
  },
]

const VerifyAttendees = ({values}) => {
  const { attendee, optionalAttendees } = values
  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Teilnehmer"
        tableHead={TableHead}
      >
        <tr>
          <td className="text-center">
            1
          </td>
          <td>
            {attendee.surname.trim()} {attendee.lastname.trim()}
          </td>
          <td>
            {congressAttendeeRoles[attendee.role].textSingular}
          </td>
        </tr>
        <RenderAttendees optionalAttendees={optionalAttendees} />
      </Table>
    </Fragment>
  )
}

const RenderAttendees = ({optionalAttendees}) => {
  const filteredOptionalAttendees = optionalAttendees
    .filter((attendee) => attendee.lastname && attendee.surname && attendee.role)

  if (filteredOptionalAttendees.length === 0) {
    return null
  }

  const attendeeList = filteredOptionalAttendees.map((attendee, index) => {
    return (
      <tr key={index}>
        <td className="text-center">
          {index + 2}
        </td>
        <td>
          {attendee.surname.trim()} {attendee.lastname.trim()}
        </td>
        <td>
          {congressAttendeeRoles[attendee.role].textSingular}
        </td>
      </tr>
    )
  })
  return (
    <Fragment>
      {attendeeList}
    </Fragment>
  )
}

export default VerifyAttendees