import React, {Fragment} from 'react'
import "./Image.scss"

export const Image = ({src, srcFallback, altText = "", className = null, width = 400}) => {
  const RenderSources = () => {
    if (!srcFallback) {
      return (
        <Fragment>
          <source 
            srcSet={src} 
            type="image/webp" 
          />
          <img 
            src={src} 
            alt={altText} 
            className={className} 
            width={width}
          />
        </Fragment>
      )
    }
    return (
      <Fragment>
        <source 
          srcSet={src} 
          type="image/webp" 
        />
        <source 
          srcSet={srcFallback} 
          type="image/jpeg" 
        /> 
        <img 
          src={srcFallback} 
          alt={altText} 
          className={className} 
          width={width}
        />
      </Fragment>
    )
  }
  return (
    <picture>
      <RenderSources />
    </picture>
  )
}