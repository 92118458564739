import React from "react"
import { Link } from "react-router-dom"
import { isLocationPath } from "./../../../utils/utils"
import "./Footer.scss"

const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <ul className="footer">
        <li className="footer-item">
          <Link 
            to="/imprint"
            className={isLocationPath("imprint") ? "text-bold footer-item-link" : "footer-item-link"}
          >
            Impressum
          </Link>
        </li>
        <li className="footer-item">
          <Link 
            to="/privacy"
            className={isLocationPath("privacy") ? "text-bold footer-item-link" : "footer-item-link"}
          >
            Datenschutz
          </Link>
        </li>
      </ul>
    </footer>
  )
}

export default Footer;