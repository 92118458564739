import React, { Fragment } from "react"
import { Price } from "./FormInput/Price"
import { Attendees } from "./FormInput/Attendees";
import { Address } from "./FormInput/Address";
import { Survey } from "./FormInput/Survey";
import HorizontalRule from "../UI/HorizontalRule/HorizontalRule"
import { ButtonRow, MiniButton } from "../UI/Button/Button";
import { CruiseDate, CruiseShip } from "../Content/Content"

const ConferenceFormInput = (props) => {
  if (process.env.NODE_ENV === "development") {
    console.log(props.values)
  }
  
  return (
    <Fragment>
      <FormHeader />
      <Price {...props} />
      <Attendees {...props} />
      <Address {...props} />
      <Survey {...props} />
      <FormFooter {...props} />
    </Fragment>
  )
}

const FormHeader = () => (
  <Fragment>
    <h1 className="h1 text-center">
      Kongressanmeldung
    </h1>
    <HorizontalRule type="wave" />
    <p className="text-intro text-center">
      <strong>Die Buchung des Fortbildungsprogramms ist nur in Verbindung mit der Buchung einer Kabine über GFB möglich. </strong> 
      Fortbildung vom <CruiseDate /> auf der <CruiseShip className="link-simple"/>
    </p>
    <HorizontalRule type="wave" />
    <p className="text-center text-grey">
      Bitte füllen Sie die Formularfelder aus. 
    </p>
  </Fragment>
)

const FormFooter = (props) => {
  return (
    <Fragment>
      <RenderNext {...props} />
    </Fragment>
  )
}

const RenderNext = ({isSubmitting, isValid}) => {
  return (
    <Fragment>
      { !isValid &&
        <p className="text-center text-small text-warning">
          Bitte füllen Sie die markierten Formularfelder aus.
        </p>
      }
      <ButtonRow hasVerticalSpace={true}>
        <MiniButton 
          link="/conference/verify"
          className="mini-button"
          large={true}
          disabled={isSubmitting || !isValid}
        >
          Weiter
        </MiniButton>
      </ButtonRow>
      <HorizontalRule />
    </Fragment>
  )
}

export default ConferenceFormInput