import React from "react"
import { Asterisk, Check, Cross } from "./../Icons/SVG"

import "./Table.scss"

export const Table = ({children, headline, hasError, tableHead = []}) => {
  const tableHasError = () => {
    if (hasError === null) {
      return
    }
    return (
      <div className={hasError ? "table-show-error has-error" : "table-show-error is-valid"}>
        <Asterisk />
      </div>
    )
  }

  const tableHeadItems = tableHead.map((item, index) => {
    return (
      <th 
        scope="col" 
        key={index} 
        style={ item.width ? {width: item.width} : {}}
        className={item && item.align && item.align === "center" ? "text-center" : null}
        colSpan={item.colSpan ? item.colSpan : null}
      >
        {item.name}
      </th>
    )
  })

  const TableHead = () => {
    if (tableHead.length === 0) {
      return null
    }
    return (
      <thead className="table-thead">
        <tr>
          {tableHeadItems}
        </tr>
      </thead>
    )
  }
  
  return (
    <div className="table-wrapper">
      <div className="table-heading">
        {headline}
        {tableHasError()}
      </div>
      <table className="table">
        <TableHead />
        <tbody className="table-tbody">
          {children}
        </tbody>
      </table>
    </div>
  )
}

export const TableIcon = ({type = "check"}) => {
  let icon

  switch (type) {
    case "check": 
      icon = <Check className="check" />
      break
    case "cross":
      icon = <Cross className="cross" />
      break
    default: 
      icon = null
  }

  return (
    <div className="table-icon-wrapper">
      {icon}
    </div>
  )
}