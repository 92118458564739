import React, { Fragment } from "react"
import { TextInput } from "../../UI/Input/Input"
import { Table } from "../../UI/Table/Table"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"

export const BillingAddress = ({values, errors}) => {
  const hasError = ["name", "street", "postcodeCity", "phoneNumber", "mobilePhoneNumber", "email"].some(value => {
    return errors && errors.billingAddress && errors.billingAddress[value]
  })

  return (
    <Fragment>
      <Table 
        hasError={hasError}
        headline="Kundenadresse"
      >
        <tr>
          <td className="text-center" width="15%">
            Name
          </td>
          <td>
            <TextInput 
              name={`billingAddress[name]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
              placeholder="Name"
            />
          </td>
        </tr>
        <tr>
          <td className="text-center">
            Straße
          </td>
          <td>
            <TextInput 
              name={`billingAddress[street]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
              placeholder="Straße"
            />
          </td>
        </tr>
        <tr>
          <td className="text-center">
            PLZ, Ort
          </td>
          <td>
            <TextInput 
              name={`billingAddress[postcodeCity]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
              placeholder="PLZ, Ort"
            />
          </td>
        </tr>
        <tr>
          <td className="text-center">
            Telefon
          </td>
          <td>
            <TextInput 
              name={`billingAddress[phoneNumber]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
              placeholder="Telefon"
            />
          </td>
        </tr>
        <tr>
          <td className="text-center">
            Fax
          </td>
          <td>
            <TextInput 
              name={`billingAddress[fax]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
              placeholder="Fax"
            />
          </td>
        </tr>
        <tr>
          <td className="text-center">
            Email
          </td>
          <td>
            <TextInput 
              name={`billingAddress[email]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
              placeholder="Email"
            />
          </td>
        </tr>
        <tr>
          <td className="text-center">
            Mobilnummer *
          </td>
          <td>
            <TextInput 
              name={`billingAddress[mobilePhoneNumber]`}
              className="one-third-width"
              hasError={true}
              errorPosition="right"
              placeholder="Mobilnummer"
            />
          </td>
        </tr>
      </Table>
      <TableFooter />
    </Fragment>
  )
}

const TableFooter = () => (
  <Fragment>
    <p className="padding-wide text-small text-center">
      * Zur Verwendung nur in Notfällen, die am Tag der Abreise, während der Kreuzfahrt oder der Rückfahrt nach Hause auftreten. Diese Informationen, in Übereinstimmung mit den Datenschutzrichtlinien, werden automatisch 7 Tage nach dem Ende der Kreuzfahrt vom System gelöscht.
    </p>
    <HorizontalRule />
  </Fragment>
)

