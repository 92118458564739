import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"

const TableHead = [
  {
    name: "",
    width: "15%"
  },
  {
    name: "Name",
    width: "35%"
  },
  {
    name: "Geburtsdatum",
  }
]

const VerifyPassengers = ({values}) => {
  const { passenger, optionalPassengers } = values
  const filteredOptionalPassengers = optionalPassengers.filter((passenger) => passenger.lastname && passenger.surname && passenger.dateOfBirth)

  const RenderOptionalPassengers = () => {
    if (!filteredOptionalPassengers) {
      return null
    }
    const optionalPassengersList = filteredOptionalPassengers.map((passenger, index) => {
      return (
        <tr key={index}>
          <td className="text-center">
            {index + 2}
          </td>
          <td>
            {passenger.surname} {passenger.lastname}
          </td>
          <td>
            {passenger.dateOfBirth}
          </td>
        </tr>
      )
    })
    return (
      <Fragment>
        {optionalPassengersList}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Teilnehmer"
        tableHead={TableHead}
      >
        <tr>
          <td className="text-center">
            1
          </td>
          <td>
            {passenger.surname} {passenger.lastname}
          </td>
          <td>
            {passenger.dateOfBirth}
          </td>
        </tr>
        <RenderOptionalPassengers />
      </Table>
    </Fragment>
  )
}

export default VerifyPassengers