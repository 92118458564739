import React from 'react'
import HorizontalRule from "../UI/HorizontalRule/HorizontalRule"
import { Image } from "../UI/Image/Image"
import { CruiseDate, CruiseShip, CruiseRoute } from "../Content/Content"
import { scrollToTop } from "../../utils/utils"


const Ship = () => {
  scrollToTop()
  
  return (
    <div className="content-wrapper">
      <p className="intro-text padding-wide">
        Wir laden Sie vom <CruiseDate className="text-no-break" /> auf der Route <CruiseRoute /> auf die <CruiseShip className="link-simple"/> ein.
      </p>

      <HorizontalRule type="wave" />
      
      <h1 className="text-center">
        Willkommen an Bord <br />
        <span className="text-intro text-normal">
          <CruiseShip className="link-simple"/> – Das Schiff Ihrer Träume
        </span>
      </h1>

      <div className="padding-wide text-intro text-center">
        <p>
          Die MSC Meraviglia bietet außergewöhnliche Bordangebote für ein perfektes Erlebnis auf See zu jeder Jahreszeit. Herausragende Restaurants, spektakuläre Unterhaltung mit atemberaubenden Panoramabereichen, eine innovative Lounge mit Meerblick, eine überdachte Innenpromenade mit 480 m<sup>2</sup> LED-Himmel und ein Vergnügungspark verbunden mit einem Wasserpark unter freiem Himmel – all das schafft eine einzigartige Atmosphäre an Bord.
        </p>
      </div>

      <HorizontalRule />

      <Image
        className="image-hasframe"
        src={require('./../../assets/images/ship/Ship-Meraviglia-galleria-meraviglia.webp')}
        srcFallback={require('./../../assets/images/ship/Ship-Meraviglia-galleria-meraviglia.jpg')}
        altText="MSC Preziosa"
        width={450}
      />

      <div className="padding-wide">
        <p className="padding-wide text-intro text-center">
          Galleria Meraviglia
        </p>
      </div>

      <HorizontalRule />

      <div className="padding-wide text-intro text-center">
        <p>
          Diese zentrale 96 m lange Promenade verfügt über Boutiquen, Restaurants sowie ruhige Orte, die sich gut zum Essen oder zum Relaxen eignen. Am Abend verwandelt sich der Platz schließlich zum Mittelpunkt von Musik, Parties und Entertainment-Angeboten.
        </p>
      </div>

      <HorizontalRule />

      <Image
        className="image-hasframe"
        src={require('./../../assets/images/ship/Ship-Meraviglia-infinity-atrium.webp')}
        srcFallback={require('./../../assets/images/ship/Ship-Meraviglia-infinity-atrium.jpg')}
        altText="MSC Preziosa"
        width={350}
      />

      <div className="padding-wide">
        <p className="padding-wide text-intro text-center">
          Der längste LED-Himmel auf See
        </p>
      </div>

      <HorizontalRule />

      <div className="padding-wide text-medium">
        <p className="padding-wide text-intro text-center">
          Die Decke der Promenade im Innenbereich wird mit einem gigantischen 480 sq.m. LED Bildschirm ausgestattet: ein digitaler Himmel, der Ihnen traumhafte Animationen bieten wird: vom Sonnenauf- bis zum Sonnenuntergang sowie ein nächtlicher Sternenhimmel. 
        </p>
      </div>

      <HorizontalRule />

      <Image
        className="image-hasframe"
        src={require('./../../assets/images/ship/Ship-Meraviglia-atmosphere-pool.webp')}
        srcFallback={require('./../../assets/images/ship/Ship-Meraviglia-atmosphere-pool.jpg')}
        altText="MSC Preziosa"
        width={450}
      />

      <div className="padding-wide">
        <p className="padding-wide text-intro text-center">
          Atmosphere Pool
        </p>
      </div>

      <HorizontalRule />

      <div className="padding-wide text-medium">
        <p className="padding-wide text-intro text-center">
          Entdecken Sie die Großzügigkeit auf dem Sonnendeck rund um den Hauptpool. Ein riesiger Bildschirm und atmosphärisches Design machen den Pool auch nach Einbruch der Dunkelheit zu einem beliebten Aufenthaltsbereich an Bord. 
        </p>
      </div>

      <HorizontalRule />

      <Image
        className="image-hasframe"
        src={require('./../../assets/images/ship/Ship-Meraviglia-horizon-pool.webp')}
        srcFallback={require('./../../assets/images/ship/Ship-Meraviglia-horizon-pool.jpg')}
        altText="MSC Preziosa"
        width={350}
      />

      <div className="padding-wide">
        <p className="padding-wide text-intro text-center">
          Pool am Heck
        </p>
      </div>

      <HorizontalRule />

      <div className="padding-wide text-medium">
        <p className="padding-wide text-intro text-center">
          Der Pool am Heck bietet tagsüber für Sonnenanbeter noch mehr Platz an Deck. Abends wird der Bereich in eine atemberaubende Location für Tanz und Unterhaltung unter dem Sternenhimmel verwandelt. 
        </p>
      </div>

      <HorizontalRule />

      <Image
        className="image-hasframe"
        src={require('./../../assets/images/ship/Ship-Meraviglia-MSC_YC.webp')}
        srcFallback={require('./../../assets/images/ship/Ship-Meraviglia-MSC_YC.jpg')}
        altText="MSC Preziosa"
        width={450}
      />

      <div className="padding-wide">
        <p className="padding-wide text-intro text-center">
          MSC Yacht Club
        </p>
      </div>

      <HorizontalRule />

      <div className="padding-wide text-medium">
        <p className="padding-wide text-intro text-center">
          Entdecken Sie unseren Yacht Club – unser Schiff-im-Schiff-Konzept, das bei der Seatrade Europe und den Cruise Days im Jahr 2017 bereits mehrfach von dem Deutschen Kreuzfahrtpreis ausgezeichnet wurde. Erleben auch Sie diesen einzigartigen Ort, in dem Sie den Luxus von Privatsphäre genießen, während Sie Zugang zum gesamten Entertainment und allen anderen Angeboten an Bord haben. Willkommen im MSC Yacht Club, eine besonders exklusive Erfahrung! 
        </p>
      </div>

      <HorizontalRule />

      <Image
        className="image-hasframe"
        src={require('./../../assets/images/ship/Ship-Meraviglia-MSC-aurea-spa.webp')}
        srcFallback={require('./../../assets/images/ship/Ship-Meraviglia-MSC-aurea-spa.jpg')}
        altText="MSC Preziosa"
        width={350}
      />

      <div className="padding-wide">
        <p className="padding-wide text-intro text-center">
          MSC Aurea Spa
        </p>
      </div>

      <HorizontalRule />

      <div className="padding-wide text-medium">
        <p className="padding-wide text-intro text-center">
          Im MSC Aurea Spa auf der MSC Meraviglia können Sie sich von Kopf bis Fuß verwöhnen lassen. Der luxuriöse balinesische Spa umfasst einen Thermalbereich, Schönheitssalon mit Friseur und ein Nagelstudio.
        </p>
      </div>

      <HorizontalRule />

      <Image
        className="image-hasframe"
        src={require('./../../assets/images/ship/Ship-Meraviglia-technogym_tcm.webp')}
        srcFallback={require('./../../assets/images/ship/Ship-Meraviglia-technogym_tcm.jpg')}
        altText="MSC Preziosa"
        width={450}
      />

      <div className="padding-wide">
        <p className="padding-wide text-intro text-center">
          MSC Gym by Technogym&reg;
        </p>
      </div>

      <HorizontalRule />

      <div className="padding-wide text-medium">
        <p className="padding-wide text-intro text-center">
          Ausgestattet mit Technogym Geräten nach dem neuesten Stand der Technik bietet das Fitnesscenter der MSC Meraviglia ein breites Spektrum an innovativen Fitness-Kursen und Trainingsprogrammen.
        </p>
      </div>

      <HorizontalRule />
    
    </div>
  )
}

export default Ship