import React from 'react'
import HorizontalRule from "./../UI/HorizontalRule/HorizontalRule"
import { CruiseDate, CruiseShip, CruiseRoute } from "./../Content/Content"
import { scrollToTop } from "./../../utils/utils"


const Archive = () => {
  scrollToTop()
  
  return (
    <div className="content-wrapper">

      <p className="intro-text">
        Wir laden Sie vom <CruiseDate className="text-no-break" /> auf der Route <CruiseRoute /> auf die <CruiseShip className="link-simple"/> ein.
      </p>

      <HorizontalRule type="wave" />
      
      <div className="padding-wide text-medium text-center">
        <h2>Archiv</h2>
        <p>
          Wir aktualisieren diese Seite in Kürze.
        </p>
      </div>
      
      <HorizontalRule type="wave"/>

    </div>
  )
}

export default Archive