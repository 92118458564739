import React, { Fragment } from "react"
import { Table, TableIcon } from "../../UI/Table/Table"
import { formatPrice } from "../../../utils/utils"
import { drinksOptions } from "../../../model/model"
import { perPersonLabel } from "../../../constants"

const VerifyDrinksOptions = ({values}) => {
  
  const { drinks } = values
  const filteredDrinksOption = drinksOptions.filter((option) => option.label === drinks)[0]
  const noDrinksOptionNeeded = filteredDrinksOption === undefined

  const RenderDrinksOption = () => {
    if (noDrinksOptionNeeded) {
      return (
        <td>
          Ich wünsche kein Getränkepaket.
        </td>
      )
    }

    const formattedPriceAdults = formatPrice(filteredDrinksOption.priceAdults, perPersonLabel) + " (Erwachsene)"
  
    return (
      <Fragment>
        <td width="35%">
          {filteredDrinksOption.name}
        </td>
        <td>
          {formattedPriceAdults}
        </td>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Buchbare Getränkepakete"
      >
        <tr>
          <td width="15%">
            <TableIcon type={noDrinksOptionNeeded ? "cross" : "check"} />
          </td>
          <RenderDrinksOption />
        </tr>
      </Table>
    </Fragment>
  )
}

export default VerifyDrinksOptions