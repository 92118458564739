import React, { Component, Fragment} from "react"
import { Switch, Route, Prompt, Redirect, matchPath } from "react-router-dom";
import { Formik, Form } from "formik"
import { isIOS } from 'react-device-detect';
import { postAllMessages, postMessage } from "./../../services/postService"
import { createMailText } from "../../services/createCruiseMailService"
import { cruiseBookingFormSchema as schema, cruiseBookingFormInitialValues as initialValues } from "./../../services/validationService"
import CruiseFormInput from "./CruiseFormInput"
import CruiseVerification from "./CruiseFormVerification"
import CruiseRegistrationConfirmation from "./CruiseRegistrationConfirmation"
import "./Cruise.scss"

class Cruise extends Component {
  state = {
    isSubmitted: false,
    hasRequestError: false
  }

  handleSubmit = (values) => {
    const mailTextClient = createMailText(values, "client")
    const mailTextAgency = createMailText(values, "agency")

    postAllMessages([
      postMessage({type: "client", data: {message: mailTextClient, subject: "Reiseanmeldung", recipient: values.billingAddress.email}}),
      postMessage({type: "agency", data: {message: mailTextAgency, subject: "Reiseanmeldung"}})
    ])
    .then(response => {
      this.setState({isSubmitted: true}, 
        () => this.props.history.push("/cruise/confirm")
      )    
    })
    .catch(error => {
      this.setState({isSubmitted: true, hasRequestError: true}, 
        () => this.props.history.push("/cruise/confirm")
      )
    })
  }

  render() {
    return (
      <Fragment>
        <Prompt
          when={!this.state.isSubmitted && !isIOS}
          message={({ pathname }) => {
            return matchPath(pathname, { path: "/cruise/register" }) || matchPath(pathname, { path: "/cruise/verify" })
              ? true
              : "Wollen Sie die Reiseanmeldung verlassen?";
          }}
        />
        <Formik 
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false)
            this.handleSubmit(values)
          }}
          isInitialValid={process.env.NODE_ENV === "development" ? true : false}
          render={({errors, touched, isSubmitting, isValid, values}) => {
            const renderProps = {
              errors, 
              touched, 
              isSubmitting, 
              isValid,
              values,
              ...this.state
            }
            
            return (
              <Form className="content-wrapper">
                <Switch>
                  <Redirect from="/cruise" exact to="/cruise/register" />
                  <Route path="/cruise/register" render={(props) => <CruiseFormInput {...props} {...renderProps}  />} />
                  <Route path="/cruise/verify" render={(props) => <CruiseVerification {...props} {...renderProps} />} />               
                  <Route path="/cruise/confirm" render={(props) => <CruiseRegistrationConfirmation {...props} {...renderProps} />} />               
                </Switch>
              </Form>
            )
          }}
        />
      </Fragment>
    )
  }
}

export default Cruise