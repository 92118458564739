import React, { Fragment } from "react"
import { Table, TableIcon } from "../../UI/Table/Table"
import { formatCabinPrice } from "../../../utils/utils"
import { cruiseModel } from "../../../model/model"

const cabinTableHead = [
  {
    name: "",
    width: "15%"
  },
  {
    name: "Kabinentyp",
    width: "35%"
  },
  {
    name: "Preis Doppelbelegung",
    width: "25%"
  },
  {
    name: "Preis Einzelbelegung",
    width: "25%"
  }
]

const VerifyCabin = ({values}) => {
  
  const { cabin } = values
  const cabinName = cruiseModel[cabin].name
  const formattedPriceSingle = formatCabinPrice(cruiseModel[cabin].price.single, {type: "singleRoom"})
  const formattedPriceDouble = formatCabinPrice(cruiseModel[cabin].price.double, {type: "singleRoom"})

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Kabine"
        tableHead={cabinTableHead}
      >
        <tr>
          <td>
            <TableIcon type="check" />
          </td>
          <td>
            {cabinName}
          </td>
          <td>
            {formattedPriceDouble}
          </td>
          <td>
            {formattedPriceSingle}
          </td>
        </tr>
      </Table>
    </Fragment>
  )
}

export default VerifyCabin