import React, { Fragment } from "react"
import { Cabins } from "./FormInput/CruiseFormInputCabins"
import { Insurance } from "./FormInput/CruiseFormInputInsurance"
import { InsurancePremiumProtection } from "./FormInput/CruiseFormInputInsurancePremiumProtection";
import { Passengers } from "./FormInput/CruiseFormInputPassengers"
import { FellowTravellers } from "./FormInput/CruiseFormInputFellowTraveller"
import { BillingAddress } from "./FormInput/CruiseFormInputBillingAddress"
import { Drinks } from "./FormInput/CruiseFormInputDrinks"
import HorizontalRule from "./../UI/HorizontalRule/HorizontalRule"
import { List, ListItem } from "./../UI/List/List"
import { ButtonRow, MiniButton } from "../UI/Button/Button";
import { ExternalLink } from "./../UI/Link/Link"
import { CruiseDate, CruiseShip, CruisingCompany, CruiseRoute } from "./../Content/Content"
import "./Cruise.scss"

const CruiseFormInput = (props) => {
  if (process.env.NODE_ENV === "development") {
    console.log(props.values)
  }
  
  return (
    <Fragment>
      <FormHeader />
      <Cabins {...props} />
      <Insurance {...props} />
      <InsurancePremiumProtection {...props} />
      <Passengers {...props} />
      <Drinks {...props} />
      <FellowTravellers />
      <BillingAddress {...props} />
      <FormFooter {...props} />
    </Fragment>
  )
}

const FormHeader = () => (
  <Fragment>
    <h1 className="h1 text-center">
      Reiseanmeldung
    </h1>
    <HorizontalRule type="wave" />
    <p className="text-intro text-center">
      Veranstalter und Schiff: <CruisingCompany className="link-simple" />, <CruiseShip className="link-simple" /> &bull; Route: <CruiseRoute /> &bull; <CruiseDate />
    </p>
    <HorizontalRule type="wave" />
    <p className="text-center text-grey">
      Bitte füllen Sie die Formularfelder aus. 
    </p>
  </Fragment>
)

const FormFooter = (props) => {
  return (
    <Fragment>
      <List className="padding-wide text-small text-justify">
        <ListItem hasDecoration>
          Anmeldung und Bestätigung erfolgen auf Basis der Reisebedingungen der Reederei MSC Cruises S.A., 40, Eugene Pittard, 1206 Genf (Schweiz), Ansprechpartner in Deutschland ist MSC Kreuzfahrten GmbH, Ridlerstraße 37, 80339 München.
        </ListItem>
        <ListItem hasDecoration>
        Der Umfang der vertraglichen Leistungen ergibt sich aus dieser Reiseanmeldung und der Leistungsbeschreibung in dem für diese Buchung maßgeblichen Preise der MSC Cruises S.A.. Es gelten des Weiteren die Allgemeinen Geschäftsbedingungen (AGB) und aktuellen Reise- und Beförderungsbedingungen der Reederei.
        </ListItem>
        <ListItem hasDecoration>
        Die aktuellen Reise- und Beförderungsbedingungen der Reederei MSC Cruises S.A. finden Sie unter 
          <ExternalLink 
            hasSpaces={true}
            className="link-underline"
            link="//www.msccruises.de/gesundheit-und-sicherheit/mittelmeer"
          >
            www.msccruises.de/gesundheit-und-sicherheit/mittelmeer
          </ExternalLink>
          .
        </ListItem>
        <ListItem hasDecoration>
          Bitte informieren Sie sich eigenständig regelmäßig über oben angegebenen Link über eventuelle Reiseeinschränkungen und Änderungen der Reederei MSC Cruises S.A., aufgrund der noch immer anhaltenden Coronalage.
        </ListItem>
        <ListItem hasDecoration>
          Wir weisen darauf hin, dass wir nur als Vermittler zwischen Ihnen und der oben genanntem Reederei auftreten. Es wurde darauf hingewiesen, eine Reiserücktrittsversicherung abzuschließen.
        </ListItem>
        <ListItem hasDecoration>
          Mit Ihrer Unterschrift erkennen Sie, zugleich für alle angemeldeten Teilnehmer, die Reisebedingungen des Veranstalters und die Beförderungsbedingungen der beteiligten Verkehrsträger als verbindlich an.
        </ListItem>
      </List>
      <HorizontalRule />
      <RenderNext {...props} />
    </Fragment>
  )
}

const RenderNext = ({isSubmitting, isValid}) => {
  return (
    <Fragment>
      { !isValid &&
        <p className="text-center text-small text-warning">
          Bitte füllen Sie die markierten Formularfelder aus.
        </p>
      }
      <ButtonRow hasVerticalSpace={true}>
        <MiniButton 
          link="/cruise/verify"
          className="mini-button"
          large={true}
          disabled={isSubmitting || !isValid}
        >
          Weiter
        </MiniButton>
      </ButtonRow>
      <HorizontalRule />
    </Fragment>
  )
}

export default CruiseFormInput