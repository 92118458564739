import React from 'react'
import { Image } from "./../Image/Image"
import { Link } from "react-router-dom"
import { TripleWave } from "./../Icons/SVG"
import "./Card.scss"

export const CardRow = ({children}) => (
  <div className="card-row">
    {children}
  </div>
)

export const Card = ({children, width}) => {
  return (
    <div className="card" style={{width: width}}>
      {children}
    </div>
  )
}

export const CardHeading = ({children}) => {
  return (
    <h1 className="card-heading">
      {children}
    </h1>
  )
}

export const CardHeadingDecoration = ({type="tripleWave"}) => {
  const defaultCSSClassName = "card-heading-decoration"
  if (type==="tripleWave") {
    return (
      <TripleWave className={defaultCSSClassName} />
    )
  }
  return null
}

export const CardAvatar = ({src, srcFallback}) => {
  return (
    <Image 
      src={src}
      srcFallback={srcFallback}
      altText="Card Avatar"
      className="card-avatar"
    />
  )
}

export const CardText = ({children}) => {
  return (
    <div className="card-text">
      {children}
    </div>
  )
}

export const CardButton = ({children, link, file=false}) => {
  if (file) {
    return (
      <a href={link} className="card-button">
        {children}
      </a>
    )
  }

  return (
    <Link to={link} className="card-button">
      {children}
    </Link>
  )
}