import React, { Fragment} from "react"
import encodeUrl from "encodeurl"
import { MiniButton, ButtonRow } from "../UI/Button/Button"
import { Table } from "../UI/Table/Table"
import HorizontalRule from "../UI/HorizontalRule/HorizontalRule"
import { createConferenceMailText as createMailText } from "../../services/createConferenceMailService"
import { postServiceRecipientAgency } from "./../../constants"

const ConferenceRegistrationConfirmation = (props) => {
  if (!props.isValid || !props.isSubmitted) {
    props.history.replace("/conference/register")
    return null
  }

  return (
    <Fragment>
      <Intro {...props} />
      <Table
        hasError={null}
        headline="Bordmanifest"
      >
        <tr>
          <td>
            <p className="text-intro text-center text-grey">
              Haben Sie das Bordmanifest schon ausgefüllt und an uns versandt? 
            </p>
            <p className="text-center">
              Das Bordmanifest muss für alle Passagiere vollständig ausgefüllt werden. Bitte beachten Sie, dass ohne ein vollständig ausgefülltes Bordmanifest keine Reiseunterlagen ausgestellt werden können.
            </p>
            <ButtonRow hasVerticalSpace={true}>
              <MiniButton 
                link="/manifest"
              >
                Bordmanifest ausfüllen
              </MiniButton>
              <MiniButton 
                link="/"
                outlined={true}
              >
                Zurück zur Startseite
              </MiniButton>
            </ButtonRow>
          </td>
        </tr>
      </Table>
      <HorizontalRule />
    </Fragment>
  )
}

const Intro = (props) => {
  const {hasRequestError, values} = props
  
  if (hasRequestError) {
    return (
      <Fragment>
        <h1 className="h1 text-center">
          Upps, beim Senden ist ein Fehler aufgetreten.
        </h1>
        <HorizontalRule type="wave" />
        <p className="text-center text-grey">
          Bitte senden Sie uns ihre Kongressanmeldung einfach per Mail. Für Rückfragen stehen wir Ihnen gerne zur Verfügung. 
        </p>
        <p className="text-center">
          <MiniButton 
            href={`mailto:${postServiceRecipientAgency[process.env.NODE_ENV]}?subject=Kongressanmeldung&body=${encodeUrl(createMailText(values, "agency"))}`}
            outlined={true}
          >
            Kongressanmeldung per Mail absenden 
          </MiniButton>
        </p>
        <HorizontalRule type="wave" />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <h1 className="h1 text-center">
        Vielen Dank für ihre Anmeldung.
      </h1>
      <HorizontalRule type="wave" />
      <p className="text-center text-grey">
        Wir werden uns mit Ihnen in Verbindung setzen. Für Rückfragen stehen wir Ihnen gerne zur Verfügung. 
      </p>
      <HorizontalRule type="wave" />
    </Fragment>
  )
}

export default ConferenceRegistrationConfirmation