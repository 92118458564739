import React from 'react'
import { ButtonRow, MiniButton } from "../UI/Button/Button"
import HorizontalRule from "./../UI/HorizontalRule/HorizontalRule"
import { CruiseDate, CruiseShip, CruiseRoute } from "./../Content/Content"
import { scrollToTop } from "./../../utils/utils"


const Contact = () => {
  scrollToTop()
  
  return (
    <div className="content-wrapper">
      
      <p className="intro-text">
        Wir laden Sie vom <CruiseDate className="text-no-break" /> auf der Route <CruiseRoute /> auf die <CruiseShip className="link-simple"/> ein.
      </p>
      
      <HorizontalRule type="wave" />
      
      <div className="padding-wide text-medium text-center">
      
        <h2>Ihre Ansprechpartner</h2>
        
        <p className="text-bold">
          Nadine Ramm, Karoline Weßlau
        </p>
        <p className="text-tracking-wide">
          Tel.: 03338 / 752716
        </p>

        <ButtonRow hasVerticalSpace={true}>
          <MiniButton
            large={true} 
            href="mailto:info@kongress-schiffsreise.de"
          >
            Kontaktanfrage 
          </MiniButton>
        </ButtonRow>
      
      </div>

      <HorizontalRule />
    
    </div>
  )
}

export default Contact