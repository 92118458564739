import currencyFormatter from "currency-formatter"
import { currencyFormatterLocale as locale } from "./../constants" 
import { requestPriceLabel, perPersonLabel } from "./../constants"
import { createConferenceMailText } from "../services/createConferenceMailService"
import { createManifestMailText } from "../services/createManifestMailService"


/**
 * Formatting Helpers
 */

export const formatCabinPrice = (price, options = {type: "doubleRoom"}) => {
  const priceLabel = {
    doubleRoom:  perPersonLabel,
    request:     requestPriceLabel
  }

  if (options.type === "doubleRoom") {
    return `${currencyFormatter.format(price, locale)} ${priceLabel.doubleRoom}`
  }

  if (options.type === "singleRoom") {
    return price !== null ? currencyFormatter.format(price, locale) : priceLabel.request
  }
  return
}

export const formatPrice = (price, label) => {
  const formattedPrice = price !== null ? currencyFormatter.format(price, locale) : ""

  if (label) {
    return `${formattedPrice} ${label}`
  }
  return formattedPrice
}

export const formatPriceWithPrefix = (price, prefix = "", suffix = "") => {
  const formattedPrice = price !== null ? currencyFormatter.format(price, locale) : ""
  const _prefix = prefix ? prefix : ""
  const _suffix = suffix ? suffix : ""

  return `${_prefix}${formattedPrice}${_suffix}`
}


/**
 * Return value depending on Node environment
 * @param {*} devValue 
 * @param {*} prodValue 
 */
export const returnOnEnvironment = (devValue, prodValue) => {
  const environment = process.env.REACT_APP_STAGE || process.env.NODE_ENV
  
  if (environment === "development") {
    return devValue
  }
  if (environment === "production") {
    return prodValue
  }
  return null
}


/**
 * Create Message Helpers
 */

export const conferenceMessageClient = (values) => ({
  type: "client", 
  data: {
    message:   createConferenceMailText(values, "client"), 
    recipient: values.address.email.trim(),
    subject:   "Kongressanmeldung", 
  }
}) 

export const conferenceMessageAgency = (values) => ({
  type: "agency", 
  data: {
    message: createConferenceMailText(values, "agency"), 
    subject: "Kongressanmeldung"
  }
})

export const manifestMessageAgency = (values) => ({
  type: "agency", 
  data: {
    message: createManifestMailText(values, "agency"), 
    subject: "Bordmanifest"
  }
})


/**
 * Various Helpers
 */

export const scrollToTop = () => {
  window.scrollTo(0, 0);
}

export const isLocationPath = (value) => (
  window.location && window.location.href ? value === window.location.href.split("/").pop() : null
)

export const getRouterBaseName = () => {
  if (process.env.NODE_ENV === "development") {
    return "/"
  }
  return process.env.REACT_APP_REMOTE_PATH || "/"
}

export const formatText = (string) => {
  let text = string || ""

  const addLineBreak = (lines) => {
    let breaks = ""
    for (let i = 0; i < lines; i++) {
      breaks += "\n"
    }
    return breaks
  }

  let obj = {
    finalize() {
      return text
    },

    t(string) {
      if (!string || typeof(string) !== "string") {
        return this
      }
      text += string
      return this
    },
    
    l(string) {
      if (!string || typeof(string) !== "string") {
        return this
      }
      text += string + addLineBreak(1)
      return this
    },

    p(string) {
      if (!string || typeof(string) !== "string") {
        return this
      }
      text += string + addLineBreak(2)
      return this
    },

    br(lines) {
      let breaks = lines
      if (typeof(lines) !== "number" || lines < 0 || lines > 10) {
        breaks = 1
      }
      text += addLineBreak(breaks)
      return this
    }
  }
  return obj
}