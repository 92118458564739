import React, { Fragment } from "react"
import "./SimpleTabs.scss"

export class SimpleTabs extends React.Component {
  state = {
    activeTab: null
  }

  handleClick = (name) => {
    this.setState({
      activeTab: name
    })
  }

  componentDidMount() {
    const tabNames = React.Children.map(this.props.children, child => {
      return child.props.name
    })

    this.setState({
      activeTab: tabNames[0]
    })
  }

  renderTabBar = () => {
    const items = React.Children.map(this.props.children, child => {
      const { name } = child.props
      const { activeTab } = this.state

      return (
        <TabsBarItem
          activeTab={name === activeTab ? true : false}
          className={this.props.tabsBarItemClassName ? this.props.tabsBarItemClassName : null}
          handleClick={this.handleClick.bind(this, name)}
        >
          {child.props.name}
        </TabsBarItem>
      )
    })
    return items
  }

  renderTabContent = () => {
    const items = React.Children.map(this.props.children, child => {
      const { name } = child.props
      const { activeTab } = this.state

      if (name !== activeTab) {
        return null
      }

      return (
        <SimpleTabsItem>
          {child}
        </SimpleTabsItem>
      )
    })
    return items
  }

  render() {
    return (
      <Fragment>
        <ul className={this.props.tabsBarClassName ? this.props.tabsBarClassName : null}>
          {this.renderTabBar()}
        </ul>
        {this.renderTabContent()}
      </Fragment>
    )
  }
}

export const TabsBarItem = ({children, activeTab, className, handleClick}) => {
  let newClassName = ""

  if (activeTab) newClassName += " active-tab"
  if (className) newClassName += ` ${className}`
  
  return (
    <li
      onClick={handleClick}
      className={newClassName ? newClassName : null}
    >
      {children}
    </li>
  )
}

export const SimpleTabsItem = ({children}) => {
  return (
    <Fragment>
      {children}
    </Fragment>
  )
}