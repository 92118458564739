import React, { Fragment } from "react"
import { TextInput } from "../../UI/Input/Input"
import { Table } from "../../UI/Table/Table"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"

const passengersTableHead = [
  {
    name: "",
    width: "15%"
  },
  {
    name: "Name",
    width: "30%"
  },
  {
    name: "Vorname",
    width: "30%"
  },
  {
    name: "Geburtsdatum",
    width: "25%"
  }
]

export const Passengers = ({errors}) => { 
  const hasError = ["surname", "lastname", "dateOfBirth"].some(value => {
    return errors && errors.passenger && errors.passenger[value]
  })
  
  return (
    <Fragment>
      <Table
        headline="Teilnehmer"
        hasError={hasError}
        tableHead={passengersTableHead}
      >
        <RenderPassengers count={4} />
      </Table>
      <HorizontalRule />
    </Fragment>
  )
}

const RenderPassengers = ({count}) => {
  const passengers = [...Array(count).keys()].map((passenger, index) => {
    return (
      <tr key={index}>
        <td className="text-center">
          {index + 1} 
        </td>
        <td>
          <TextInput 
            name={index === 0 ? `passenger.lastname` : `optionalPassengers[${index-1}][lastname]`}
            className="full-width"
            placeholder="Name"
            hasError={index === 0 ? true : false}
          />   
        </td>
        <td>
          <TextInput 
            name={index === 0 ? `passenger.surname` : `optionalPassengers[${index-1}][surname]`}
            className="full-width"
            placeholder="Vorname"
            hasError={index === 0 ? true : false}
          />      
        </td>
        <td>
          <TextInput 
            name={index === 0 ? `passenger.dateOfBirth` : `optionalPassengers[${index-1}][dateOfBirth]`}
            className="full-width"
            placeholder="tt.mm.jjjj"
            hasError={index === 0 ? true : false}
          />   
        </td>
      </tr>
    )
  })
  return passengers
}