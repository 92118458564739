import React, { Fragment } from "react"

export const ExternalLink = ({className, hasSpaces, link, children}) => {
  const Link = () => (
    <a 
      href={link}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )

  if (hasSpaces) {
    return (
      <Fragment>
        {` `}
        <Link />
        {` `}
      </Fragment>
    )
  }
  return (
    <Link />
  )
}