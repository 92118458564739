import React from 'react'
import HorizontalRule from "../UI/HorizontalRule/HorizontalRule"
import { Image } from "../UI/Image/Image"
import { CruiseDate, CruiseShip, CruiseRoute, EarlyBirdDiscount, BookActions } from "../Content/Content"
import { DestinationDates } from "./DestinationDates"
import { scrollToTop } from "../../utils/utils"
import "./Destinations.scss"

const Destinations = () => {
  scrollToTop()
  return (
    <div className="content-wrapper">

      <p className="intro-text">
        Wir laden Sie vom <CruiseDate className="text-no-break" /> auf der Route <CruiseRoute /> auf die <CruiseShip className="link-simple"/> ein.
      </p>

      <HorizontalRule type="wave" />

      <h1 className="text-center">
        Ihre Reiseroute
      </h1>

      <Image
        className="image-hasframe"
        src={require('./../../assets/images/destinations/destinations.webp')}
        altText="Route 2024"
        width="400"
      />
      
      <p className="text-intro text-center">
        <CruiseRoute />
      </p>
      
      <HorizontalRule type="wave" />
      <DestinationDates />

      <HorizontalRule />
      <BookActions />
      <EarlyBirdDiscount className="text-intro text-center" />

      <HorizontalRule type="wave" />
      
    </div>
  )
}

export default Destinations