import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"
import { TextInput } from "../../UI/Input/Input"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"

export const ClubNumber = () => (
  <Fragment>
    <Table 
      hasError={null}
      headline="MSC Voyager Club Nummer"
    >
      <RenderPassengers count={4} />
    </Table>
    <HorizontalRule />
  </Fragment>
)

const RenderPassengers = ({count}) => {
  const passengerList = [...Array(count).keys()].map((index) => (
    <tr key={index}>
      <td
        width="25%" 
        className="text-center"
      >
        {`${index + 1}. Person`}
        <br />(optionale Angabe)	
      </td>
      <td>
        <TextInput 
          name={index === 0 ? "passenger[mscVoyagerClubNumber]" : `optionalPassengers[${index - 1}][mscVoyagerClubNumber]`}
          className="one-third-width"
        />
      </td>
    </tr>
  ))
  return (
    <Fragment>
      {passengerList}
    </Fragment>
  )
}