import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"

const VerifyClubNumber = ({values}) => {
  const { passenger, optionalPassengers } = values

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="MSC Voyager Club Nummer"
      >
        <tr>
          <td className="text-center" width="25%">
            1. Person
          </td>
          <td>
            {`${passenger.mscVoyagerClubNumber ? passenger.mscVoyagerClubNumber : 'Keine Angabe'}`}
          </td>
        </tr>
        <tr>
          <td className="text-center" width="25%">
            2. Person
          </td>
          <td>
            {`${optionalPassengers[0].mscVoyagerClubNumber ? optionalPassengers[0].mscVoyagerClubNumber : 'Keine Angabe'}`}
          </td>
        </tr>
        <tr>
          <td className="text-center" width="25%">
            3. Person
          </td>
          <td>
            {`${optionalPassengers[1].mscVoyagerClubNumber ? optionalPassengers[1].mscVoyagerClubNumber : 'Keine Angabe'}`}
          </td>
        </tr>
        <tr>
          <td className="text-center" width="25%">
            4. Person
          </td>
          <td>
            {`${optionalPassengers[2].mscVoyagerClubNumber ? optionalPassengers[2].mscVoyagerClubNumber : 'Keine Angabe'}`}
          </td>
        </tr>
       
      </Table>
    </Fragment>
  )
}

export default VerifyClubNumber