import axios from "axios"
import formurlencoded from 'form-urlencoded'
import { REMOTE_PATH } from "./../config"
import { postServiceRecipientAgency, postServiceRecipientClient, postServiceFrom } from "./../constants"

export const postMessage = (options) => {
  const {data: {message, subject, recipient}} = options
  const type =  options.type || "client"
  const stage = process.env.REACT_APP_STAGE || "development"
  const from =  postServiceFrom[stage]

  const getRecipient = (type, stage) => {
    if (type === "agency") {
      return postServiceRecipientAgency[stage]
    }

    if (type === "client") {
      if (stage === "development") {
        return postServiceRecipientClient[stage]
      }
      return recipient
    }

    return null
  }

  const to = getRecipient(type, stage)

  return axios
    .post(`${REMOTE_PATH}worker.php`, dataPayload(to, from, subject, message), customHeaderConfig)
}

export const postAllMessages = (promises = []) => {
  return (
    Promise.all(promises)
  )
}

const dataPayload = (recipient, from, subject, message) => {
  return formurlencoded({
    recipient: recipient,
    from: from,
    subject: subject,
    message: base64Encode(message)
  })
}

const base64Encode = (string) => {
  const utf8EncodedString = require('utf8').encode(string)
  const base64EncodedString = require('base-64').encode(utf8EncodedString)
  
  return base64EncodedString
}

/**
 * Find meta elements with given name and return its content for first tag found
 * @param {*} Meta element name 
 */
const getMetaElementContentWithName = (name) => {
  const metaNodes = document.getElementsByTagName("meta")
  for (let i = 0; i < metaNodes.length; i++) {
    if (metaNodes[i].getAttribute("name") === name) {
      return metaNodes[i].getAttribute("content")
    }
  }
  return;
}

const customHeaderConfig = {
  headers: {
    "content-type": "application/x-www-form-urlencoded",
    "Csrftoken": getMetaElementContentWithName("csrf-token"),
    "cache-control": "no-cache"
  }
}











