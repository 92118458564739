import React from "react"
import Waves from './HorizontalRuleWaves'
import "./HorizontalRule.scss"

const HorizontalRule = ({ type }) => {
  const decoration = type === "wave" ? <Waves className="horizontal-rule-decoration" /> : null
  const className = type === "wave" ? "horizontal-rule has-decoration" : "horizontal-rule"

  return (
    <div className={className}>
      {decoration}
    </div> 
  )
}

export default HorizontalRule