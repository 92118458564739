import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"

const tableHead = [
  {
    name: "",
    width: "15%"
  },{
    name: "Name",
    width: "21%"
  },{
    name: "Nationalität",
    width: "21%"
  },{
    name: "Geburtsdatum",
    width: "21%"
  },{
    name: "Geburtsort",
    width: "21%"
  }
]

const VerifyPersonalData = ({values}) => {
  const { passenger, optionalPassengers } = values
  const filteredOptionalPassengers = optionalPassengers.filter((passenger) => passenger.lastname && passenger.surname && passenger.dateOfBirth && passenger.placeOfBirth && passenger.nationality)

  const RenderOptionalPassengers = () => {
    if (!filteredOptionalPassengers) {
      return null
    }
    const optionalPassengersList = filteredOptionalPassengers.map((passenger, index) => {
      return (
        <tr key={index}>
          <td className="text-center">
            {index + 2}. Person
          </td>
          <td>
            {passenger.surname} {passenger.lastname}
          </td>
          <td>
            {passenger.nationality}
          </td>
          <td>
            {passenger.dateOfBirth}
          </td>
          <td>
            {passenger.placeOfBirth}
          </td>
        </tr>
      )
    })
    return (
      <Fragment>
        {optionalPassengersList}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Persönliche Angaben"
        tableHead={tableHead}
      >
        <tr>
          <td className="text-center">
            1. Person
          </td>
          <td>
            {passenger.surname} {passenger.lastname}
          </td>
          <td>
            {passenger.nationality}
          </td>
          <td>
            {passenger.dateOfBirth}
          </td>
          <td>
            {passenger.placeOfBirth}
          </td>
        </tr>
        <RenderOptionalPassengers />
      </Table>
    </Fragment>
  )
}

export default VerifyPersonalData