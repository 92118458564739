export const perPersonLabel = "je Person"
export const perPersonPerNightLabel = "je Person je Nacht"
export const requestPriceLabel =  "Preis auf Anfrage"

export const cruise = {
  route:    "Kiel – Kopenhagen – Hellesylt/Geiranger – Alesund – Flam – Kiel",
  date:     "21. Juni 2025 – 28. Juni 2025",
  ship:     "MSC Euribia",
  shipLink: "https://www.msccruises.de/unsere-kreuzfahrten/kreuzfahrtschiffe/msc-euribia",
  shippingCompanyLink: "https://www.msc-kreuzfahrten.de"
}

export const currencyFormatterLocale = { locale: 'de-DE' }

export const postServiceRecipientAgency = {
  development:  "i.am.andreas.75@gmail.com",
  production:   "info@kongress-schiffsreise.de" // change this for testing purposes, eg. with dev email
}

export const postServiceRecipientClient = {
  development: "i.am.andreas.75@gmail.com"
}

export const postServiceFrom = {
  development:  "From: Andreas Wagner <i.am.andreas.75@gmail.com>",
  production:   "From: Nadine Ramm <info@kongress-schiffsreise.de>"
}