import React from "react";

const Waves = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="64" height="10" viewBox="0 0 64 10">
    <g fillRule="evenodd">
      <path d="M63.688 2.537c-2.98-.837-4.923-.132-6.704 1.134-1.321.939-3.44 2.95-5.45 4.379-1.608 1.143-3.436 1.766-5.648 1.903-2.647.192-5.291-.208-7.886-1.15l.091-.402c2.973.335 4.54-.355 5.975-1.376 1.896-1.347 4.835-4.11 6.386-5.213 1.493-1.06 3.617-1.979 5.96-1.786 2.67.128 5.321 1.03 7.588 2.036l-.312.475zM44.688 2.537c-2.98-.837-4.923-.132-6.704 1.134-1.321.939-3.44 2.95-5.45 4.379-1.608 1.143-3.436 1.766-5.648 1.903-2.647.192-5.291-.208-7.886-1.15l.091-.402c2.973.335 4.54-.355 5.975-1.376 1.896-1.347 4.835-4.11 6.386-5.213 1.493-1.06 3.617-1.979 5.96-1.786 2.67.128 5.321 1.03 7.588 2.036l-.312.475zM25.688 2.537c-2.98-.837-4.923-.132-6.704 1.134-1.321.939-3.44 2.95-5.45 4.379-1.608 1.143-3.436 1.766-5.648 1.903-2.647.192-5.291-.208-7.886-1.15l.091-.402c2.973.335 4.54-.355 5.975-1.376 1.896-1.347 4.835-4.11 6.386-5.213 1.493-1.06 3.617-1.979 5.96-1.786 2.67.128 5.321 1.03 7.588 2.036l-.312.475z"/>
    </g>
  </svg>
)

export default Waves