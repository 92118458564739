import React, { Fragment} from "react"
import encodeUrl from "encodeurl"
import { ButtonRow, MiniButton } from "../UI/Button/Button"
import HorizontalRule from "../UI/HorizontalRule/HorizontalRule"
import { createManifestMailText as createMailText } from "../../services/createManifestMailService"
import { postServiceRecipientAgency } from "./../../constants"

const ManifestConfirmation = (props) => {
  if (!props.isValid || !props.isSubmitted) {
    props.history.replace("/manifest/register")
    return null
  }

  return (
    <Fragment>
      <Intro {...props} />
      <HorizontalRule />
    </Fragment>
  )
}

const Intro = (props) => {
  const {hasRequestError, values} = props
  
  if (hasRequestError) {
    return (
      <Fragment>
        <h1 className="h1 text-center">
          Upps, beim Senden ist ein Fehler aufgetreten.
        </h1>
        <HorizontalRule type="wave" />
        <p className="text-center text-grey">
          Bitte senden Sie uns das Bordmanifest einfach per Mail zu. Für Rückfragen stehen wir Ihnen gerne zur Verfügung. 
        </p>
        <ButtonRow hasVerticalSpace={true}>
          <MiniButton 
            href={`mailto:${postServiceRecipientAgency[process.env.NODE_ENV]}?subject=Bordmanifest&body=${encodeUrl(createMailText(values, "agency"))}`}
            outlined={true}
          >
            Bordmanifest per Mail absenden 
          </MiniButton>
          <MiniButton 
            link="/"
            outlined={true}
          >
            Zurück zur Startseite
          </MiniButton>
        </ButtonRow>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <h1 className="h1 text-center">
        Vielen Dank für ihre Anmeldung.
      </h1>
      <HorizontalRule type="wave" />
      <p className="text-center text-grey">
        Wir werden uns mit Ihnen in Verbindung setzen. Für Rückfragen stehen wir Ihnen gerne zur Verfügung. 
      </p>
      <ButtonRow hasVerticalSpace={true}>
        <MiniButton 
          link="/"
          outlined={true}
        >
          Zurück zur Startseite
        </MiniButton>
      </ButtonRow>
    </Fragment>
  )
}

export default ManifestConfirmation