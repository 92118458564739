import { congressAttendeeRoles, congressPrice } from "../model/model"
import { cruise, perPersonLabel } from "../constants"
import { formatText, formatPrice } from "../utils/utils"

export const createConferenceMailText = (values, type = "client") => {
  const getIntroTextClient = () => {
    return formatText()
      .p("Sehr geehrte Damen und Herren,")
      .l(`vielen Dank für ihre Kongressanmeldung für die Kongress-Schiffsreise am ${cruise.date} auf der Route ${cruise.route}. Unten finden Sie noch einmal ihre Anmeldedaten zusammengefasst. Haben Sie das Bordmanifest schon ausgefüllt und an uns versandt? Bitte beachten Sie, dass ohne ein vollständig ausgefülltes Bordmanifest keine Reiseunterlagen ausgestellt werden können.`)
      .finalize()
  }

  const getIntroTextAgency = () => {
    return formatText()
      .p("Sehr geehrte Damen und Herren,")
      .l(`eine Kongressanmeldung für die Kongress-Schiffsreise am ${cruise.date} auf der Route ${cruise.route} wurde verschickt. Unten finden Sie die zugehörigen Formulardaten.`)
      .finalize()
  }

  const getAttendeesText = () => {
    const { attendee: { surname, lastname, role }, optionalAttendees } = values
    const filteredAttendees = optionalAttendees
      .filter(attendee => (attendee.surname && attendee.lastname && attendee.role))
      .map(attendee => (`${attendee.surname} ${attendee.lastname} (${congressAttendeeRoles[attendee.role].textMale}, ${formatPrice(congressPrice[attendee.role], perPersonLabel)})`))
      .join(", \n")

    return formatText()
      .p("Teilnehmer:")
      .l(`${surname} ${lastname} (${congressAttendeeRoles[role].textMale}, ${formatPrice(congressPrice[role], perPersonLabel)})`)
      .l(filteredAttendees)
      .finalize()
  }

  const getAddressText = () => {
    const { address: { name, street, postcodeCity, email, phoneNumber }} = values
    return formatText()
      .p("Praxis-Adresse:")
      .l(name)
      .l(street)
      .l(postcodeCity)
      .l(email)
      .l(phoneNumber)
      .finalize()
  }

  const getSurveyText = () => {
    const { survey: { agency, partner, campaign, card, text }} = values
    
    if (!agency && !partner && !campaign && !card && !text) {
      return ""
    }
    
    return formatText()
      .p("Umfrage: Woher haben Sie von dieser Fortbildung erfahren?")
      .l(agency ? "GFB-Internetseite" : "")
      .l(partner ? "Henry Schein Dental Deutschland GmbH" : "")
      .l(campaign ? "Info-Mail" : "")
      .l(card ? "Postkarte" : "")
      .l(text ? `Sonstiges: ${text.trim()}` : "")
      .finalize()
  }

  return (
    formatText()
      .l(type === "client" ? getIntroTextClient() : getIntroTextAgency())
      .l(getAttendeesText())
      .l(getAddressText())
      .l(getSurveyText())
      .finalize()
    )
}