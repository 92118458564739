import React from 'react'
import { Link } from "react-router-dom" 
import "./Button.scss"

export const ButtonRow = ({children, hasVerticalSpace = false, justifyItems = "center"}) => {
  let className = "button-row"

  if (hasVerticalSpace) {
    className += " has-vertical-space"
  }

  switch (justifyItems) {
    case "center":
      className += " justify-center"
      break
    case "space-around":
      className += " justify-around"
      break
    case "space-between":
      className += " justify-between"
      break
    default:
      className += ""
  }

  return (
    <div className={className}>
      {children}
    </div>
  )
}

export const Button = ({link = "#", children}) => {
  return (
    <Link to={link} className="button">
      <div className="button-label">
        {children}
      </div>
    </Link>
  )
}

export const MiniButton = ({children, link, href, file = false, disabled = false, large = false, outlined = false}) => {
  let className = "mini-button"
  
  if (large) {
    className += " large"
  }

  if (outlined) {
    className += " outlined"
  }

  if (file) {
    return (
      <a 
        href={link} 
        className={className} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }

  if (disabled) {
    return (
      <span className={`${className} disabled`}>
        {children}
      </span>
    )
  }

  if (href) {
    return (
      <a 
        href={href} 
        className={className} 
      >
        {children}
      </a>
    )
  }

  return (
    <Link 
      to={link} 
      className={className}
    >
      {children}
    </Link>
  )
}

