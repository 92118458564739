import React, { Fragment } from "react"
import { Table, TableIcon } from "../../UI/Table/Table"

const TableHead = [
  {
    name: "Woher haben Sie von dieser Fortbildung erfahren?",
    align: "center",
    colSpan: 2
  }
]

const VerifySurvey = ({values}) => {
  const { survey: {agency, partner, campaign, card, text} } = values

  if (!agency && !partner && !campaign && !text) {
    return null
  }

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Umfrage"
        tableHead={TableHead}
      >
        <RenderSurveyItem label="GFB-Internetseite" data={agency} />
        <RenderSurveyItem label="Henry Schein Dental Deutschland GmbH" data={partner} />
        <RenderSurveyItem label="Info-Mail" data={campaign} />
        <RenderSurveyItem label="Postkarte" data={card} />
        <RenderSurveyItemText data={text} />
      </Table>
    </Fragment>
  )
}

const RenderSurveyItem = ({label, data}) => {
  if (!data) {
    return null
  }

  return (
    <tr>
      <td width="15%">
        <TableIcon type="check" />
      </td>
      <td>
        {label}
      </td>
    </tr>
  )
}

const RenderSurveyItemText = ({data}) => {
  if (!data) {
    return null
  }

  return (
    <tr>
      <td 
        width="15%"
        className="text-center"  
      >
        Sonstiges
      </td>
      <td>
        {data.trim()}
      </td>
    </tr>
  )
}

export default VerifySurvey