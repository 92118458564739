import React, { Fragment } from "react"
import { Field } from "formik"
import { Table } from "../../UI/Table/Table"
import { TextInput } from "../../UI/Input/Input"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"

const tableHead = [
  {
    name: "Woher haben Sie von dieser Fortbildung erfahren?",
    colSpan: 2,
    align: "center"
  }
]

export const Survey = ({values}) => {
  return (
    <Fragment>
      <Table 
        headline="Umfrage"
        tableHead={tableHead}
        hasError={null}
      >
        <tr>
          <td width="15%">
            <Field 
              type="checkbox" 
              name="survey.agency"
              id="survey.agency"
              className="radio-input justified"
              checked={values.survey.agency}
            />
          </td>
          <td>
            <label htmlFor={`survey.agency`}>
              GFB Internetseite
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <Field 
              type="checkbox" 
              name="survey.partner"
              id="survey.partner"
              className="radio-input justified"
              checked={values.survey.partner}
            />
          </td>
          <td>
            <label htmlFor={`survey.partner`}>
              Henry Schein Dental Deutschland GmbH
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <Field 
              type="checkbox" 
              name="survey.campaign"
              id="survey.campaign"
              className="radio-input justified"
              checked={values.survey.campaign}
            />
          </td>
          <td>
            <label htmlFor={`survey.campaign`}>
              Info-Mail
            </label>
          </td>
        </tr>

        <tr>
          <td>
            <Field 
              type="checkbox" 
              name="survey.card"
              id="survey.card"
              className="radio-input justified"
              checked={values.survey.card}
            />
          </td>
          <td>
            <label htmlFor={`survey.card`}>
              Postkarte
            </label>
          </td>
        </tr>
        
        <tr>
          <td className="text-center">
            <label htmlFor={`survey.text`}>
              Sonstiges
            </label>
          </td>
          <td>
            <TextInput 
              name={`survey.text`}
              className="one-third-width"
              placeholder="Bitte ausfüllen..."
            />
          </td>
        </tr>
      </Table>
      <TableFooter />
    </Fragment>
  )
}

const TableFooter = () => (
  <Fragment>
    <HorizontalRule />
  </Fragment>
)