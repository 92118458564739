import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"
import { TextInput, SelectInput } from "../../UI/Input/Input"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"
import { congressAttendeeRoles } from "./../../../model/model"

const tableHead = [
  {
    name: "",
    width: "15%"
  },
  {
    name: "Name",
    width: "28%"
  },
  {
    name: "Vorname",
    width: "28%"
  },
  {
    name: "Rolle"
  }
]

export const Attendees = ({values, errors}) => {
  const hasError = ["lastname", "surname", "role"].some(value => {
    return errors && errors.attendee && errors.attendee[value]
  })

  return (
    <Fragment>
      <Table 
        hasError={hasError}
        headline="Teilnehmer"
        tableHead={tableHead}
      >
        <AttendeesList count={4} errors={errors} />
      </Table>
      <TableFooter />
    </Fragment>
  )
}

const AttendeesList = ({count, errors}) => {
  const attendees = [...Array(count).keys()].map((attendee, index) => {
    return (
      <tr key={index}>
        <td className="text-center">
          {index + 1} 
        </td>
        <td>
          <TextInput 
            name={index === 0 ? `attendee.lastname` : `optionalAttendees[${index-1}][lastname]`}
            className="full-width"
            placeholder="Name"
            hasError={index === 0 ? true : false}
          />
        </td>
        <td>
          <TextInput 
            name={index === 0 ? `attendee.surname` : `optionalAttendees[${index-1}][surname]`}
            className="full-width"
            placeholder="Vorname"
            hasError={index === 0 ? true : false}
          />      
        </td>
        <td className={index === 0 ? "align-top" : ""}>
          <SelectInput
            component="select"
            name={index === 0 ? `attendee.role` : `optionalAttendees[${index-1}][role]`}
            className={index === 0 ? "full-width margin-top-oneeight" : "full-width"}
            hasError={index === 0}
          > 
            <Fragment>
              <option value="">
                Bitte wählen ...
              </option>
              <Options />
            </Fragment>
          </SelectInput>
        </td>
      </tr>
    )
  })
  return attendees
}

const Options = () => {
  const options = Object.keys(congressAttendeeRoles).map((role, index) => (
    <option 
      value={congressAttendeeRoles[role].label}
      key={index}
    >
      {congressAttendeeRoles[role].textMale}
    </option>
  ))
  return options
}

const TableFooter = () => (
  <Fragment>
    <p className="padding-wide text-small text-center">
      Die Fortbildung wird mit bis zu 25 Punkten zertifiziert. (Bewertung gemäß BZÄK und DGZMK)<br />
      Es gelten die AGB der Gesundheitszentrum Fortbildungsinstitut Berlin-Brandenburg GmbH
    </p>
    <HorizontalRule />
  </Fragment>
)