import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"
import { TextInput, SelectInput } from "../../UI/Input/Input"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"
import { travelDocumentTypes } from "./../../../model/model" 

const tableHead = [
  {
    name: "",
    width: "15%"
  },{
    name: "Reisedokument",
    align: "center",
    width: "17%"
  },{
    name: "Personal- oder Reisepass-Nr.",
    align: "center",
    width: "17%"
  },{
    name: "Ausstellungsort",
    align: "center",
    width: "17%"
  },{
    name: "Ausstellungsdatum",
    align: "center",
    width: "17%"
  },{
    name: "Gültig bis",
    align: "center",
    width: "17%"
  }
]

export const TravelDocument = ({errors}) => (
  <Fragment>
    <Table 
      hasError={errors.travelDocument}
      headline="Reisedokumente"
      tableHead={tableHead}
    >
      <RenderDocuments count={4} />
    </Table>
    <TableFooter />
  </Fragment>
)

const RenderDocuments = ({count}) => {
  const passengerList = [...Array(count).keys()].map((index) => (
    <tr key={index}>
      <td
        width="15%" 
        className="text-center"
      >
        {`${index + 1}. Person`}	
      </td>
      <td>
        <SelectInput
          component="select"
          name={index === 0 ? "travelDocument.type" : `optionalTravelDocuments[${index - 1}][type]`}
          className={index === 0 ? "full-width margin-top-oneeight" : "full-width"}
          hasError={index === 0 ? true : false}
        >
          <option value="">
            Bitte wählen ...
          </option>
          <TravelDocumentOptions />
        </SelectInput>
      </td>
      <td>
        <TextInput 
          name={index === 0 ? "travelDocument[number]" : `optionalTravelDocuments[${index - 1}][number]`}
          className="full-width"
          hasError={index === 0 ? true : false}
        />
      </td>
      <td>
        <TextInput 
          name={index === 0 ? "travelDocument[issuePlace]" : `optionalTravelDocuments[${index - 1}][issuePlace]`}
          className="full-width"
          hasError={index === 0 ? true : false}
        />
      </td>
      <td>
        <TextInput 
          name={index === 0 ? "travelDocument[issueDate]" : `optionalTravelDocuments[${index - 1}][issueDate]`}
          className="full-width"
          hasError={index === 0 ? true : false}
        />
      </td>
      <td>
        <TextInput 
          name={index === 0 ? "travelDocument[validUntil]" : `optionalTravelDocuments[${index - 1}][validUntil]`}
          className="full-width"
          hasError={index === 0 ? true : false}
        />
      </td>
    </tr>
  ))
  return (
    <Fragment>
      {passengerList}
    </Fragment>
  )
}

const TravelDocumentOptions = () => {
  const options = Object.keys(travelDocumentTypes).map((document, index) => (
    <option 
      value={document}
      key={index}
    >
      {travelDocumentTypes[document].text}
    </option>
  ))
  return options
}

const TableFooter = () => (
  <Fragment>
    <p className="text-center text-bold">
      Ihr Reisepass muss eine Gültigkeit von mindestens 6 Monaten ab Datum der Einreise besitzen.
    </p>
    <HorizontalRule />
  </Fragment>
)