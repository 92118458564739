import React from "react"
import Cookies from "js-cookie"
import { Link } from "react-router-dom"
import { Cross } from "./../UI/Icons/SVG"
import { COOKIE_LAYER_EXPIRES } from "./../../config"
import "./CookieLayer.scss"

export default class CookieLayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPrivacyLayer: false
    }
  }

  componentDidMount() {
    if (Cookies.get("showPrivacyLayer") === undefined || Cookies.get("showPrivacyLayer") === "true") {
      this.showCookieLayer()
    }
  }

  showCookieLayer = () => {
    Cookies.set('showPrivacyLayer', "true", { expires: COOKIE_LAYER_EXPIRES })
    this.setState(() => ({
      showPrivacyLayer: true
    }))
  }

  hideCookieLayer = () => {
    Cookies.set('showPrivacyLayer', "false", { expires: COOKIE_LAYER_EXPIRES })
    this.setState(() => ({
      showPrivacyLayer: false
    }))
  }

  render() {
    if (!this.state.showPrivacyLayer) {
      return null
    } 

    return (
      <div className="cookie-layer">
        <div 
          onClick={this.hideCookieLayer}
          className="cookie-layer-control"
        >
          <Cross/>
        </div>
        <div className="cookie-layer-wrapper">
          <p className="text-medium">
            Wir verwenden Cookies, um Ihnen das beste Erlebnis auf unserer Webseite zu ermöglichen. Wenn Sie Ihren Besuch auf unserer Website fortsetzen, gehen wir davon aus, dass Sie Cookies akzeptieren.   
            Weiterführende Informationen erhalten Sie in unserer {``}
            <Link 
              to="/privacy"
              className="link-underline"
            >
              Datenschutzerklärung
            </Link>.
          </p>
          <p>
            <button 
              onClick={this.hideCookieLayer}
              className="mini-button alternate-hover"
            >
              OK
            </button>
          </p>
        </div>
      </div>
    )
  }
}