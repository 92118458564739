import React from 'react'
import HorizontalRule from "./../UI/HorizontalRule/HorizontalRule"
import { Card, CardHeading, CardHeadingDecoration, CardAvatar, CardText, CardButton, CardRow } from "./../UI/Card/Card"
import { CruiseShip, BookActions, EarlyBirdDiscount } from "./../Content/Content"
import "./Home.scss";

const Home = () => {
  return (
    <div className="content-wrapper">
      <p className='intro-text'>
        Unsere 20. Kongress-Schiffsreise führt Sie auf der <CruiseShip className="link-simple"/> von Kiel aus über Kopenhagen durch die norwegische Fjorde. 
        Verbinden Sie die Annehmlichkeiten der Kreuzfahrt mit einer interessanten und vielseitigen Fortbildungsveranstaltung für Zahnärzte, Zahntechniker und Ihr gesamtes Team.
      </p>
      <HorizontalRule type="wave" />
      <p className='text-center'>
        Die MSC Euribia steht für zukunftsorientierte und umweltfreundliche Technologien zum Schutz und Erhalt der wertvollen maritimen Ökosysteme.
        Das neueste Schiff der MSC Flotte wird durch den Einsatz von Flüssiggas LNG, Abwasseraufbereitung, Abfallrecycling und den Einsatz hocheffizienter energiesparender Geräte seiner Umweltverantwortung gerecht.
        Das Leitmotiv „SaveTheSea“ ziert kunstvoll den Schiffsrumpf des neuesten MSC Schiffes.
      </p>
      <HorizontalRule type="wave" />
      <BookActions />
      <EarlyBirdDiscount className="text-intro text-center" />
      <HorizontalRule type="wave" />
      <CardRow>
        <Card width="50%"> 
          <CardHeading>
            Anmeldung
            <CardHeadingDecoration type="tripleWave" />
          </CardHeading>
          <CardAvatar 
            src={require("./../../assets/images/fishes-on-wall-1.webp")} 
            srcFallback={require("./../../assets/images/fishes-on-wall-1.png")} 
          />
          <CardText>
            <p>Flyer und Anmeldung zum bequemen Ausdrucken in der Praxis</p>
          </CardText>
          <CardButton 
            file={true} 
            link="./flyer/flyer-2025.pdf"
          >
            Jetzt Downloaden
          </CardButton>
        </Card>
        <Card width="50%">
          <CardHeading>
            Kontakt
            <CardHeadingDecoration type="tripleWave" />
          </CardHeading>
          <CardAvatar 
            src={require("./../../assets/images/fishes-on-wall-2.webp")} 
            srcFallback={require("./../../assets/images/fishes-on-wall-2.png")} 
          />
          <CardText>
            <p>Gesundheitszentrum Fortbildungsinstitut Bernau GmbH</p>
            <p>Tel 03338 – 752716 / Fax 03338 – 752729</p>
          </CardText>
          <CardButton link="/contact">
            Kontakt
          </CardButton>
        </Card>
      </CardRow>
      <HorizontalRule type="wave" />
    </div>
  )
}

export default Home