import React, { Fragment } from "react"
import { ButtonRow, MiniButton } from "../UI/Button/Button";
import { scrollToTop } from "./../../utils/utils"
import HorizontalRule from "./../UI/HorizontalRule/HorizontalRule"
import VerifyAttendees from "./FormVerification/Attendees";
import VerifyAddress from "./FormVerification/Address";
import VerifySurvey from "./FormVerification/Survey";
import { createConferenceMailText as createMailText } from "../../services/createConferenceMailService"

const ConferenceVerification = (props) => {
  if (!props.isValid) {
    props.history.replace("/conference/register")
    return null
  }

  if (process.env.NODE_ENV === "development") {
    console.log(createMailText(props.values, "client"))
  }
  
  scrollToTop()
  
  return (
    <Fragment>
      <Header />
      <VerifyAttendees {...props} />
      <VerifyAddress {...props} />
      <VerifySurvey {...props} />
      <Footer {...props} />
    </Fragment>
  )
}

const Header = () => (
  <Fragment>
    <h1 className="h1 text-center">
      Kongressanmeldung
    </h1>
    <HorizontalRule type="wave" />
    <p className="text-center text-grey">
      Bitte überprüfen Sie ihre Daten. 
    </p>
    <HorizontalRule />
  </Fragment>
)

const Footer = (props) => {
  return (
    <Fragment>
      <HorizontalRule />
      <RenderBack {...props} />
    </Fragment>
  )
}

const RenderBack = ({isSubmitting, isValid}) => {
  return (
    <Fragment>
      <ButtonRow justifyItems="space-around">
        <MiniButton 
          link="/conference/register"
          className="mini-button"
          large={true}
        >
          Zurück
        </MiniButton>
        <button 
          className="mini-button" 
          type="submit" 
          disabled={!isValid || isSubmitting}
        >
          Absenden
        </button>
      </ButtonRow>
      <HorizontalRule />
    </Fragment>
  )
}

export default ConferenceVerification