import { cruiseModel, drinksOptions } from "../model/model"
import { formatText, formatCabinPrice, formatPrice } from "../utils/utils"
import { cruise, perPersonLabel } from "../constants"

export const createMailText = (values, type = "client") => {
  const { 
    passenger,
    optionalPassengers, 
    billingAddress, 
    cabin, 
    insurance,
    insurancePremiumProtection,
    drinks,
    fellowTravellers 
  } = values

  const getIntroTextClient = () => {
    return formatText()
      .p("Sehr geehrte Damen und Herren,")
      .l(`vielen Dank für ihre Reiseanmeldung für die Kongress-Schiffsreise vom ${cruise.date} auf der Route ${cruise.route}. Unten finden Sie noch einmal ihre Anmeldedaten zusammengefasst. Haben Sie das Bordmanifest schon ausgefüllt und an uns versandt? Bitte beachten Sie, dass ohne ein vollständig ausgefülltes Bordmanifest keine Reiseunterlagen ausgestellt werden können.`)
      .finalize()
  }

  const getIntroTextAgency = () => {
    return formatText()
      .p("Sehr geehrte Damen und Herren,")
      .l(`eine Reiseanmeldung für die Kongress-Schiffsreise vom ${cruise.date} auf der Route ${cruise.route} wurde verschickt. Unten finden Sie die zugehörigen Formulardaten.`)
      .finalize()
  }

  const getPassengersText = () => {
    const textPassenger = formatText()
      .p("Teilnehmer:")
      .l(`${passenger.surname} ${passenger.lastname} (${passenger.dateOfBirth})`)
      .finalize()
    
    const filteredPassengers = optionalPassengers
      .filter(passenger => (passenger.surname && passenger.lastname && passenger.dateOfBirth))
      .map(passenger => (`${passenger.surname} ${passenger.lastname} (${passenger.dateOfBirth})`))
      .join(", \n")

    return formatText(textPassenger)
      .l(filteredPassengers)
      .finalize()
  }

  const getBillingAddressText = () => {
    return formatText()
      .p("Rechnungsadresse:")
      .l(billingAddress.name)
      .l(billingAddress.street)
      .l(billingAddress.postcodeCity)
      .l(billingAddress.email)
      .l(billingAddress.mobilePhoneNumber)
      .l(billingAddress.phoneNumber)
      .l(billingAddress.fax)
      .finalize()
  }

  const getCabinText = () => {
    return formatText()
      .p("Kabine:")
      .l(cruiseModel[cabin].name)
      .l(`Doppelbelegung: ${formatCabinPrice(cruiseModel[cabin].price.double)}`)
      .l(`Einzelbelegung: ${formatCabinPrice(cruiseModel[cabin].price.single, {type: "singleRoom"})}`)
      .finalize()
  }

  const getInsuranceText = () => {
    return formatText()
      .l("Reiserücktrittskostenversicherung: " + (
        insurance ? 
          "Ich wünsche den Abschluss einer Reiserücktrittskostenversicherung." : "Ich wünsche keinen Abschluss einer Reiserücktrittskostenversicherung."
        ))
      .finalize()
  }

  const getInsurancePremiumProtectionText = () => {
    return formatText()
      .l("MSC Premiumschutz: " + (
        insurancePremiumProtection ? 
          "Ich wünsche den Abschluss des MSC Premiumschutzes." : "Ich wünsche keinen Abschluss des MSC Premiumschutzes."
        ))
      .finalize()
  }

  const getDrinkOptionText = () => {
    const filteredDrinksOption = drinksOptions.filter((option) => option.label === drinks)[0]
    const drinkOptionFormattedPriceAdults = formatPrice(filteredDrinksOption.priceAdults, perPersonLabel) + " (Erwachsene)"
    
    return formatText()
      .l(`Getränkeoption: ${filteredDrinksOption.name}, ${drinkOptionFormattedPriceAdults}`)
      .finalize()
  }

  const getFellowTravellersText = () => {
    let filteredFellowTravellers = fellowTravellers.filter((fellow) => fellow).join(", \n")
    let text = formatText("Gewünschte Tischnachbarn: ")

    if (filteredFellowTravellers) {
      return text
        .p(" ")
        .l(filteredFellowTravellers)
        .finalize()
    }

    return text
      .t("Keine gewünschten Tischnachbarn")
      .finalize()
  }

  return (
    formatText()
      .l(type === "client" ? getIntroTextClient() : getIntroTextAgency())
      .l(getPassengersText())
      .l(getBillingAddressText()) 
      .l(getCabinText())
      .l(getInsuranceText())
      .l(getInsurancePremiumProtectionText())
      .l(getDrinkOptionText()) 
      .l(getFellowTravellersText())
      .finalize()
  )          
}