import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"
import { TextInput } from "../../UI/Input/Input"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"

export const Emergency = ({errors}) => (
  <Fragment>
    <Table 
      hasError={errors.emergency}
      headline="Im Notfall ist folgende Person zu benachrichtigen"
    >
      <tr>
        <td
          width="25%" 
          className="text-center">
            Name	
        </td>
        <td>
          <TextInput 
            name="emergency[name]"
            className="one-third-width"
            placeholder="Name"
            hasError={true}
            errorPosition="right"
          />
        </td>
      </tr>
      <tr>
        <td className="text-center">
          Telefonnummer		
        </td>
        <td>
          <TextInput 
            name="emergency[phone]"
            className="one-third-width"
            placeholder="Telefonnummer"
            hasError={true}
            errorPosition="right"
          />
        </td>
      </tr>
    </Table>
    <TableFooter />
  </Fragment>
)

const TableFooter = () => (
  <Fragment>
    <HorizontalRule />
  </Fragment>
)