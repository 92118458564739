import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import CookieLayer from "./components/CookieLayer/CookieLayer"
import Home from "./components/Home/Home"
import Cruise from "./components/Cruise/Cruise"
import Conference from "./components/Conference/Conference"
import Manifest from './components/Manifest/Manifest';
import Header from "./components/Layout/Header/Header"
import Footer from "./components/Layout/Footer/Footer"
import Imprint from "./components/Imprint/Imprint"
import Privacy from "./components/Privacy/Privacy"
import Archive from "./components/Archive/Archive"
import Contact from "./components/Contact/Contact"
import Sessions from "./components/Sessions/Sessions"
import Destinations from "./components/Destinations/Destinations"
import Ship from "./components/Ship/Ship"
import { ROUTER_BASENAME } from "./config"

import "normalize.css"
import './App.scss'

const Layout = ({children}) => {
  return (
    <div className="layout-wrapper">
      {children}
    </div>
  )
}

class App extends Component {
  render() {
    return (
      <Router basename={ROUTER_BASENAME}>
        <Layout>
          <CookieLayer />
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/cruise" component={Cruise} />
            <Route path="/conference" component={Conference} />
            <Route path="/manifest" component={Manifest} />
            <Route exact path="/imprint" component={Imprint} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/archive" component={Archive} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/sessions" component={Sessions} />
            <Route exact path="/destinations" component={Destinations} />
            <Route exact path="/ship" component={Ship} />
            <Route component={Home} />
          </Switch>
          <Footer />
        </Layout>
      </Router>
    )
  }
}

export default App
