import React, { Fragment } from "react"
import { ButtonRow, MiniButton } from "../UI/Button/Button";
import { scrollToTop } from "./../../utils/utils"
import HorizontalRule from "./../UI/HorizontalRule/HorizontalRule"
import VerifyCabin from "./FormVerification/CruiseFormVerificationCabin"
import VerifyInsurance from "./FormVerification/CruiseFormVerificationInsurance"
import VerifyDrinksOptions from "./FormVerification/CruiseFormVerificationDrinks"
import VerifyPassenger from "./FormVerification/CruiseFormVerificationPassenger"
import VerifyFellowTravellers from "./FormVerification/CruiseFormVerificationFellowTravellers"
import VerifyBillingAddress from "./FormVerification/CruiseFormVerificationBillingAddress"
import { createMailText } from "../../services/createCruiseMailService"
import VerifyInsurancePremiumProtection from "./FormVerification/CruiseFormVerificationInsurancePremiumProtection";

const CruiseVerification = (props) => {
  if (!props.isValid) {
    props.history.replace("/cruise/register")
    return null
  }

  if (process.env.NODE_ENV === "development") {
    console.log(createMailText(props.values, "client"))
  }
  
  scrollToTop()
  
  return (
    <Fragment>
      <Header />
      <VerifyCabin {...props} />
      <VerifyPassenger {...props} />
      <VerifyInsurance {...props} />
      <VerifyInsurancePremiumProtection {...props} />
      <VerifyDrinksOptions {...props} />
      <VerifyFellowTravellers {...props} />
      <VerifyBillingAddress {...props} />
      <Footer {...props} />
    </Fragment>
  )
}

const Header = () => (
  <Fragment>
    <h1 className="h1 text-center">
      Reiseanmeldung
    </h1>
    <HorizontalRule type="wave" />
    <p className="text-center text-grey">
      Bitte überprüfen Sie ihre Daten. 
    </p>
    <HorizontalRule />
  </Fragment>
)

const Footer = (props) => {
  return (
    <Fragment>
      <HorizontalRule />
      <RenderBack {...props} />
    </Fragment>
  )
}

const RenderBack = ({isSubmitting, isValid}) => {
  return (
    <Fragment>
      <ButtonRow justifyItems="space-around">
        <MiniButton 
          link="/cruise/register"
          className="mini-button"
          large={true}
        >
          Zurück
        </MiniButton>
        <button 
          className="mini-button" 
          type="submit" 
          disabled={!isValid || isSubmitting}
        >
          Absenden
        </button>
      </ButtonRow>
      <HorizontalRule />
    </Fragment>
  )
}

export default CruiseVerification