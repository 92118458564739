import React, { Component, Fragment} from "react"
import { Switch, Route, Prompt, Redirect, matchPath } from "react-router-dom";
import { Formik, Form } from "formik"
import { isIOS } from 'react-device-detect';
import { conferenceRegistrationSchema as schema, conferenceRegistrationInitialValues as initialValues } from "./../../services/conferenceValidationService"
import { postAllMessages, postMessage } from "./../../services/postService"
import { conferenceMessageClient as messageClient, conferenceMessageAgency as messageAgency } from "./../../utils/utils"
import ConferenceFormInput from "./ConferenceFormInput"
import ConferenceVerification from "./ConferenceFormVerification"
import ConferenceRegistrationConfirmation from "./ConferenceRegistrationConfirmation"
import "./Conference.scss"

class Conference extends Component {
  state = {
    isSubmitted: false,
    hasRequestError: false
  }

  handleSubmit = (values) => {
    postAllMessages([
      postMessage(messageClient(values)),
      postMessage(messageAgency(values))
    ])
    .then(response => {
      this.setState({isSubmitted: true}, 
        () => this.props.history.push("/conference/confirm")
      )    
    })
    .catch(error => {
      this.setState({isSubmitted: true, hasRequestError: true}, 
        () => this.props.history.push("/conference/confirm")
      )
    })
  }

  render() {
    return (
      <Fragment>
        <Prompt
          when={!this.state.isSubmitted && !isIOS}
          message={({ pathname }) => {
            return matchPath(pathname, { path: "/conference/register" }) || matchPath(pathname, { path: "/conference/verify" })
              ? true
              : "Wollen Sie die Kongressanmeldung verlassen?";
          }}
        />
        <Formik 
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false)
            this.handleSubmit(values)
          }}
          isInitialValid={process.env.NODE_ENV === "development" ? true : false}
          render={({errors, touched, isSubmitting, isValid, values}) => {
            const renderProps = {
              errors, 
              touched, 
              isSubmitting, 
              isValid,
              values,
              ...this.state
            }
            
            return (
              <Form className="content-wrapper">
                <Switch>
                  <Redirect from="/conference" exact to="/conference/register" />
                  <Route path="/conference/register" render={(props) => <ConferenceFormInput {...props} {...renderProps}  />} />
                  <Route path="/conference/verify" render={(props) => <ConferenceVerification {...props} {...renderProps} />} />               
                  <Route path="/conference/confirm" render={(props) => <ConferenceRegistrationConfirmation {...props} {...renderProps} />} />               
                </Switch>
              </Form>
            )
          }}
        />
      </Fragment>
    )
  }
}

export default Conference