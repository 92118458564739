import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"
import { TextInput } from "../../UI/Input/Input"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"

const tableHead = [
  {
    name: "",
    width: "15%"
  },{
    name: "Vorname",
    align: "center"
  },{
    name: "Familienname",
    align: "center"
  },{
    name: "Nationalität",
    align: "center"
  },{
    name: "Geburtsdatum",
    align: "center"
  },{
    name: "Geburtsort",
    align: "center"
  }
]

export const PersonalData = ({errors}) => (
  <Fragment>
    <Table 
      hasError={errors.passenger}
      headline="Persönliche Angaben"
      tableHead={tableHead}
    >
      <RenderPassengers count={4} />
    </Table>
    <TableFooter />
  </Fragment>
)

const RenderPassengers = ({count}) => {
  const passengerList = [...Array(count).keys()].map((index) => (
    <tr key={index}>
      <td
        width="15%" 
        className="text-center"
      >
        {`${index + 1}. Person`}	
      </td>
      <td>
        <TextInput 
          name={index === 0 ? "passenger[surname]" : `optionalPassengers[${index - 1}][surname]`}
          className="full-width"
          hasError={index === 0 ? true : false}
        />
      </td>
      <td>
        <TextInput 
          name={index === 0 ? "passenger[lastname]" : `optionalPassengers[${index - 1}][lastname]`}
          className="full-width"
          hasError={index === 0 ? true : false}
        />
      </td>
      <td>
        <TextInput 
          name={index === 0 ? "passenger[nationality]" : `optionalPassengers[${index - 1}][nationality]`}
          className="full-width"
          hasError={index === 0 ? true : false}
        />
      </td>
      <td>
        <TextInput 
          name={index === 0 ? "passenger[dateOfBirth]" : `optionalPassengers[${index - 1}][dateOfBirth]`}
          className="full-width"
          hasError={index === 0 ? true : false}
        />
      </td>
      <td>
        <TextInput 
          name={index === 0 ? "passenger[placeOfBirth]" : `optionalPassengers[${index - 1}][placeOfBirth]`}
          className="full-width"
          hasError={index === 0 ? true : false}
        />
      </td>
    </tr>
  ))
  return (
    <Fragment>
      {passengerList}
    </Fragment>
  )
}

const TableFooter = () => (
  <Fragment>
    <HorizontalRule />
  </Fragment>
)