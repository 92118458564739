import React from "react"
import { FastField, ErrorMessage } from "formik"
import "./Input.scss"

export const TextInput = ({name, className, placeholder, hasError=false, errorPosition = "bottom"}) => {
  let wrapperClassName = "input-wrapper"

  if (hasError) {
    switch (errorPosition) {
      case "bottom":
        wrapperClassName = `${wrapperClassName} error-position-bottom`
        break
      case "right":
        wrapperClassName = `${wrapperClassName} error-position-right`
        break
      default: 
        wrapperClassName = `${wrapperClassName}`
    }
  }

  return (
    <div className={wrapperClassName}>
      <FastField 
        className={className ? `input ${className}` : "input"} 
        name={name} 
        placeholder={placeholder} 
        type="text" 
      />
      { hasError &&
        <ErrorMessage 
          className={`input-error ${errorPosition}`} 
          name={name} 
          component="span" 
        />
      }
    </div>
  )
}

export const RadioInput = ({name, className, value, values}) => {
  return (
    <FastField 
      className={className ? `radio-input ${className}` : "radio-input"} 
      checked={values[name] === value}
      id={value}
      name={name}
      type="radio" 
      value={value}
    />
  )
}

export const CheckboxInput = ({name, className, value, values}) => {
  return (
    <FastField 
      className={className ? `radio-input ${className}` : "radio-input"} 
      checked={values[name]}
      id={name}
      name={name}
      type="checkbox" 
      value={value}
    />
  )
}

export const SelectInput = ({children, name, className, hasError=false, errorPosition = "bottom"}) => {
  let wrapperClassName = "input-wrapper"

  if (hasError) {
    switch (errorPosition) {
      case "bottom":
        wrapperClassName = `${wrapperClassName} error-position-bottom`
        break
      case "right":
        wrapperClassName = `${wrapperClassName} error-position-right`
        break
      default: 
        wrapperClassName = `${wrapperClassName}`
    }
  }

  return (
    <div className={wrapperClassName}>
      <FastField 
        component="select"
        className={className ? className : ""} 
        name={name} 
      >
        {children}
      </FastField>
      { hasError &&
        <ErrorMessage 
          className={`input-error ${errorPosition}`} 
          name={name} 
          component="span" 
        />
      }
    </div>
  )
}