import React from 'react'
import { Star } from "./../Icons/SVG"
import "./List.scss"

export const List = ({children, className}) => {
  const listClassName = className ? `list ${className}` : "list"
  
  return (
    <ul className={listClassName}>
      {children}
    </ul>
  )
}

export const ListItem = ({children, hasDecoration = false}) => {
  if (hasDecoration) {
    return (
      <li className="has-decoration">
        <Star className="list-icon-star" />
        {children}
      </li>
    )
  }
  return (
    <li>
      {children}
    </li>
  )
}

