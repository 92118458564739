import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"

const TableHead = [
  {
    name: "",
    width: "15%"
  },
  {
    name: "Name"
  }
]

const VerifyFellowTravellers = ({values}) => {
  const { fellowTravellers } = values
  const filteredfellowTravellers = fellowTravellers.filter((fellow) => fellow)

  if (filteredfellowTravellers.length === 0) {
    return null
  }

  const RenderFellowTravellers = () => {
    const fellowsList = filteredfellowTravellers.map((fellow, index) => {
      return (
        <tr key={index}>
          <td className="text-center">
            {index + 1}
          </td>
          <td>
            {fellow}
          </td>
        </tr>
      )
    })
    return (
      <Fragment>
        {fellowsList}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Gewünschte Tischnachbarn"
        tableHead={TableHead}
      >
        <RenderFellowTravellers />
      </Table>
    </Fragment>
  )
}

export default VerifyFellowTravellers