import React from 'react'
import HorizontalRule from "./../UI/HorizontalRule/HorizontalRule"
import { Table } from "./../UI/Table/Table"
import { Star } from "./../UI/Icons/SVG"
import { CruiseDate, CruiseShip, CruiseRoute, BookActions, EarlyBirdDiscount } from "./../Content/Content"
import { scrollToTop } from "./../../utils/utils"
import { conferenceSessions } from "./../../model/model"

const Sessions = () => {
  scrollToTop()

  return (
    <div className="content-wrapper">
      
      <p className="intro-text">
        Wir laden Sie vom <CruiseDate className="text-no-break" /> auf der Route <CruiseRoute /> auf die <CruiseShip className="link-simple"/> ein.
      </p>

      <HorizontalRule type="wave" />
      
      <h1 className="text-center">
        Ihr Fortbildungsprogramm<br />
        <span className="text-intro text-normal">
          Geplante Themen 2025
        </span>
      </h1>

      <Table  
        headline={null}
        hasError={null}
      >
        <RenderSessions />
      </Table>

      <HorizontalRule />
      <BookActions />
      <EarlyBirdDiscount className="text-intro text-center" />
      <HorizontalRule />

      <div className="padding-wide text-small text-center">
        <p>
          Diese Fortbildung wird mit bis zu 25 Punkten zertifiziert. (Bewertung gemäß BZÄK und DGZMK)
          Es gelten die {``}
            <a 
              href="//www.kongress-schiffsreise.de/media/agb/AGB-GFB.pdf" 
              className="link-underline" 
              target="_blank"
              rel="noopener noreferrer"  
            >
              AGB
            </a> {``}
            der Gesundheitszentrum Fortbildungsinstitut Bernau GmbH. Irrtümer und Änderungen vorbehalten.
        </p>
      </div>

      <HorizontalRule />
    </div>
  )
}

const RenderSessions = () => {
  const sessions = conferenceSessions.map((session, index) => (
    <tr key={index}>
      <td className="left-indent-wide25">
        <Star className="text-icon" /> {session}
      </td>
    </tr>
  ))
  return sessions
}

export default Sessions