import React, { Fragment } from "react"
import { RadioInput } from "../../UI/Input/Input"
import { Table } from "../../UI/Table/Table"
import { List, ListItem } from "../../UI/List/List"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"
import { formatPrice } from "../../../utils/utils"
import { drinksOptions } from "../../../model/model"

const drinksTableHead = [
  {
    name: "",
    width: "15%"
  },
  {
    name: "Getränke Option",
    width: "35%"
  },
  {
    name: "Preis pro Erwachsenen ab 18 J.",
    align: "center"
  }
]

export const Drinks = ({values}) => { 
  return (
    <Fragment>
      <Table 
        hasError={null}
        headline="Buchbare Getränkepakete"
        tableHead={drinksTableHead}
      >
        <RenderDrinks data={drinksOptions} values={values} />
        {/* <tr className="table-spacing-row" />
        <tr>
          <td>
            <RadioInput 
              name={`drinks`}
              className="justified"
              values={values}
              value="none"
              />
          </td>
          <td colSpan="4">
            <label htmlFor="none">
              Ich wünsche kein Getränkepaket.
            </label>
          </td>
        </tr> */}
      </Table>
      <TableFooter />
    </Fragment>
  )
}

const RenderDrinks = ({data, values}) => {
  const drinks = data.map((drink, index) => {
    return (
      <tr key={index}>
        <td className="text-center">
          <RadioInput 
            name="drinks"
            className="justified"
            values={values}
            value={drink.label}
          />
        </td>
        <td>
          <label htmlFor={drink.label}>
            {drink.name}
          </label>
        </td>
        <td className="text-center">
          {formatPrice(drink.priceAdults)}
        </td>
      </tr>
    )
  })
  return drinks
}

const TableFooter = () => {
  const items = drinksOptions.map((item, index) => {
    const {name, description} = item

    return (
      <ListItem 
        hasDecoration={true} 
        key={index}
      >
        <span className="text-underline text-tracking-wide">
          {name}:
        </span>
        &nbsp;{description}
      </ListItem>
    )
  })
  return (
    <Fragment>
      <List className="padding-wide text-small text-justify">
        {items}
      </List>
      <HorizontalRule />
    </Fragment>
  )
}