import React from 'react'
import { Table } from "../UI/Table/Table"

const tableHead = [
  {
    name: "Tag",
    width: "30%",
    align: "center"
  },
  {
    name: "Routenverlauf",
    width: "30%"
  },
  {
    name: "Ankunft",
    width: "20%"
  },
  {
    name: "Abfahrt",
    width: "20%"
  }
]

export const DestinationDates = () => {
  return (
    <div className="padding-wide">
      <div className="padding-wide">
        <Table
          hasError={null}
          headline="Reiseplan"
          tableHead={tableHead}
        >
          <tr>
            <td className="text-center">Samstag. 21. Juni 2025</td>
            <td>Kiel, Deutschland</td>
            <td></td>
            <td>19:00 Uhr</td>
          </tr>

          <tr>
            <td className="text-center">Sonntag, 22 Juni 2025</td>
            <td>Kopenhagen, Dänemark</td>
            <td>08:00 Uhr</td>
            <td>18:00 Uhr</td>
          </tr>

          <tr>
            <td className="text-center">Montag, 23 Juni 2025</td>
            <td>Auf See</td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td className="text-center">Dienstag, 24 Juni 2025</td>
            <td>Hellesylt, Norwegen</td>
            <td>09:00 Uhr</td>
            <td>09:45 Uhr</td>
          </tr>

          <tr>
            <td className="text-center">Dienstag, 24 Juni 2025</td>
            <td>Geiranger, Norwegen</td>
            <td>12:00 Uhr</td>
            <td>21:00 Uhr</td>
          </tr>

          <tr>
            <td className="text-center">Mittwoch, 25 Juni 2025</td>
            <td>Molde, Norwegen</td>
            <td>07:00 Uhr</td>
            <td>15:00 Uhr</td>
          </tr>

          <tr>
            <td className="text-center">Donnerstag, 26 Juni 2025</td>
            <td>Flam, Norwegen</td>
            <td>08:00 Uhr</td>
            <td>18:00 Uhr</td>
          </tr>

          <tr>
            <td className="text-center">Freitag, 27 Juni 2025</td>
            <td>Auf See</td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td className="text-center">Samstag, 28 Juni 2025</td>
            <td>Kiel, Deutschland</td>
            <td>09:00 Uhr</td>
            <td></td>
          </tr>
        </Table>
      </div>
    </div>
  )
}