import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"

const BillingAddressKeysMapping = {
  name: "Name",
  street: "Straße",
  postcodeCity: "PLZ Ort",
  phoneNumber: "Telefon",
  mobilePhoneNumber: "Mobil",
  fax: "Fax",
  email: "Email"
}

const VerifyBillingAddress = ({values}) => {
  const { billingAddress } = values
  const filteredBillingAddressKeys = Object.keys(billingAddress)
    .filter(value => billingAddress[value])
    
  const RenderBillingAddress = () => {
    const filteredBillingAddress = filteredBillingAddressKeys.map((item, index) => {
      return (
        <tr key={index}> 
          <td width="15%" className="text-center">
            {BillingAddressKeysMapping[item]}
          </td>
          <td>
            {billingAddress[item]}
          </td>
        </tr>
      )
    })
    return (
      <Fragment>
        {filteredBillingAddress}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Kundenadresse"
      >
        <RenderBillingAddress />
      </Table>
    </Fragment>
  )
}

export default VerifyBillingAddress