import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"
import { travelDocumentTypes } from "./../../../model/model" 

const tableHead = [
  {
    name: "",
    width: "15%"
  },{
    name: "Reisedokument",
    width: "17%"
  },{
    name: "Personal- oder Reisepass-Nr.",
    width: "17%"
  },{
    name: "Ausstellungsort",
    width: "17%"
  },{
    name: "Ausstellungsdatum",
    width: "17%"
  },{
    name: "Gültig bis",
    width: "17%"
  }
]

const VerifyTravelDocument = ({values}) => {
  const { travelDocument: { type, number, issueDate, issuePlace, validUntil}, optionalTravelDocuments } = values
  const filteredOptionalTravelDocuments = optionalTravelDocuments
    .filter((document) => document.type && document.number && document.issueDate && document.issuePlace && document.validUntil)

  const RenderOptionalPassengers = () => {
    if (!filteredOptionalTravelDocuments) {
      return null
    }
    const optionalList = filteredOptionalTravelDocuments.map((document, index) => {
      const { type, number, issueDate, issuePlace, validUntil } = document
      return (
        <tr key={index}>
          <td className="text-center">
            {index + 2}. Person
          </td>
          <td>
            {travelDocumentTypes[type].text}
          </td>
          <td>
            {number}
          </td>
          <td>
            {issuePlace}
          </td>
          <td>
            {issueDate}
          </td>
          <td>
            {validUntil}
          </td>
        </tr>
      )
    })
    return (
      <Fragment>
        {optionalList}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Reisedokumente"
        tableHead={tableHead}
      >
        <tr>
          <td className="text-center">
            1. Person
          </td>
          <td>
            {travelDocumentTypes[type].text}
          </td>
          <td>
            {number}
          </td>
          <td>
            {issuePlace}
          </td>
          <td>
            {issueDate}
          </td>
          <td>
            {validUntil}
          </td>
        </tr>
        <RenderOptionalPassengers />
      </Table>
    </Fragment>
  )
}

export default VerifyTravelDocument